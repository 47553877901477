import { CardNumber, CardNumberProps } from '@clarke-energia/foton-v2';

import { formatCurrency } from '@utils/text';

import style from './style.module.css';

const getRatio = (value: number | null) => {
  let ratioValue = '';
  if (value !== null) {
    ratioValue = Math.abs(value).toFixed(0);
  }

  let direction = 'none';
  if (value !== null) {
    if (value > 0) {
      direction = 'up';
    } else if (value < 0) {
      direction = 'down';
    }
  }

  return {
    direction,
    value: ratioValue && ratioValue + '%',
  } as CardNumberProps['ratio'];
};
interface ConsumptionParam {
  value: number | null;
  percent: number | null;
}
export interface ConsumptionsCardsProps {
  projections?: {
    consumption?: ConsumptionParam;
  };

  accumulatedConsumptionsParams?: {
    cost: ConsumptionParam;
    economy: ConsumptionParam;
    consumption: ConsumptionParam;
  };
}

const ConsumptionsCards = (props: ConsumptionsCardsProps) => {
  let consumption = props.projections?.consumption;
  let economy, cost;

  if (props.accumulatedConsumptionsParams) {
    consumption = props.accumulatedConsumptionsParams.consumption;
    economy = props.accumulatedConsumptionsParams.economy;
    cost = props.accumulatedConsumptionsParams.cost;
  }

  return (
    <>
      {!props.projections && (
        <>
          {consumption && (
            <CardNumber
              title="Consumo"
              value={`${consumption.value ? consumption.value.toFixed(3) : '-'} MWh`}
              variant="compact"
              icon={{
                name: 'LightBulbIcon',
              }}
              ratio={consumption.percent ? getRatio(consumption.percent) : undefined}
              className={style.innerCard}
              tooltip={consumption.percent ? { content: 'Em comparação ao mês anterior' } : undefined}
            />
          )}
          {economy && (
            <CardNumber
              title="Economia"
              value={economy.value ? formatCurrency(economy.value) : 'R$ -'}
              variant="compact"
              icon={{
                name: 'BanknotesIcon',
              }}
              ratio={economy.percent ? getRatio(economy.percent) : undefined}
              className={style.innerCard}
              tooltip={economy.percent ? { content: 'Em comparação ao mês anterior' } : undefined}
            />
          )}
          {cost && (
            <CardNumber
              title="Custo"
              value={cost.value ? formatCurrency(cost.value) : 'R$ -'}
              variant="compact"
              icon={{
                name: 'CurrencyDollarIcon',
              }}
              ratio={cost.percent ? getRatio(cost.percent) : undefined}
              className={style.innerCard}
              tooltip={cost.percent ? { content: 'Em comparação ao mês anterior' } : undefined}
            />
          )}
        </>
      )}
      {props.projections && (
        <>
          {consumption && (
            <CardNumber
              title="Previsão de consumo"
              subtitle="com base no histórico dos últimos meses"
              value={`${consumption.value ? consumption.value.toFixed(3).replace('.', ',') : '-'} kWh`}
              variant="compact"
              icon={{
                name: 'LightBulbIcon',
              }}
              ratio={consumption.percent ? getRatio(consumption.percent) : undefined}
              tooltip={consumption.percent ? { content: 'Em comparação ao mês anterior' } : undefined}
              className={style.innerCard}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConsumptionsCards;
