import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GetGroupMigrationReportGraphQL } from './types';
import { GET_MIGRATION_REPORT_BY_GROUP_ID } from './queries';

export const useGroupMigrationStatus = () => {
  const [groupHasMigrated, setGroupHasMigrated] = React.useState<boolean>();
  const [hasAtLeastOneMigratedUnit, setHasAtLeastOneMigratedUnit] = React.useState<boolean>(false);

  const [getMigrationReportByGroup, { data, loading, error }] = useLazyQuery<GetGroupMigrationReportGraphQL>(
    GET_MIGRATION_REPORT_BY_GROUP_ID,
    {
      fetchPolicy: 'network-only',
    },
  );

  function getMigrationStatusByGroupHandler(groupId: string) {
    return getMigrationReportByGroup({ variables: { groupId } });
  }

  useEffect(() => {
    if (data) {
      for (const unit of data.getMigrationReportByGroupId?.units || []) {
        if (unit.conclusionPercentage === 1) {
          setHasAtLeastOneMigratedUnit(true);
          break;
        }
      }
      setGroupHasMigrated(data.getMigrationReportByGroupId.conclusionPercentage === 1);
    }
  }, [data]);

  return {
    getGroupHasMigrated: getMigrationStatusByGroupHandler,
    groupHasMigrated,
    hasAtLeastOneMigratedUnit,
    data: data && data.getMigrationReportByGroupId,
    loading,
    error,
  };
};
