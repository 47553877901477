import { CardNumberRatioDirection, SelectOption } from '@clarke-energia/foton-v2';

import {
  UnitReportParsed,
  UnitEconomyReportDataResponse,
  GroupAccumulatedEconomyReportByGroupIdParsed,
} from '@hooks/economy/types';

import { conventionalMarketTablesGenerator, freeMarketTablesGenerator } from '@hooks/economy/parser';

import { CostChartDataItems, Unit } from './types';

export const defaultUnitFilter = {
  label: "Todas as UC's",
  value: "Todas as UC's",
};

export const defaultPeriodFilter = {
  label: 'Todo o período',
  value: 'Todo',
};

export const buildUnitFilter = (units: Unit[]): SelectOption[] => {
  const newFilters = [defaultUnitFilter];
  if (units && units.length > 0) {
    units.forEach((e) => {
      newFilters.push({
        value: e.id,
        label: e.name,
      });
    });
  }

  return newFilters;
};

export const buildPeriodFilter = (unitReports: Record<string, UnitReportParsed[]>): SelectOption[] => {
  const filter = [defaultPeriodFilter];
  if (unitReports) {
    const labels = Object.keys(unitReports).reverse();

    labels.forEach((label) => {
      filter.push({
        label: label,
        value: label,
      });
    });
  }
  return filter;
};

export const getFreeMarketDataTable = (unitReport: UnitEconomyReportDataResponse | undefined) => {
  return unitReport ? freeMarketTablesGenerator(unitReport) : [];
};

export const getConventionalMarketDataTable = (unitReport: UnitEconomyReportDataResponse | undefined) => {
  return unitReport ? conventionalMarketTablesGenerator(unitReport) : [];
};

export const getUnitsFromReport = (rawUnitReports: UnitEconomyReportDataResponse[]) => {
  const searchUnits: Unit[] = [];

  rawUnitReports.forEach(({ unit }) => {
    const found = searchUnits.find((f) => {
      return f.id == unit.id;
    });

    if (!found) {
      searchUnits.push({ id: unit.id, name: unit.name });
    }
  });

  return searchUnits;
};

export const getGroupAccumulatedEconomyReportByPeriod = (
  period: string,
  report: GroupAccumulatedEconomyReportByGroupIdParsed,
) => {
  return (
    report.summaryEconomyReportsHistory?.find((reportHistory) => {
      return reportHistory.date === period;
    }) ?? undefined
  );
};

export const getUnitAccumulatedEconomyReportById = (unitId: string, reports: UnitReportParsed[]) => {
  return reports.find((report) => {
    return report.unitId == unitId;
  });
};

export const getUnitEconomyReportByIdAndPeriod = (
  unitId: string,
  period: string,
  unitsReports: Record<string, UnitReportParsed[]>,
  rawHistoryUnitEconomyReport: UnitEconomyReportDataResponse[],
) => {
  const reportsByPeriod = unitsReports[period];

  if (!reportsByPeriod) {
    return null;
  }

  const reportByUnitId = reportsByPeriod.find((report) => {
    return report.unitId == unitId;
  });

  return reportByUnitId ? getReportById(reportByUnitId.reportId, rawHistoryUnitEconomyReport) ?? null : null;
};

export const getReportById = (
  reportId: string | undefined,
  reports: UnitEconomyReportDataResponse[],
): UnitEconomyReportDataResponse | undefined => {
  return reports.find((report) => report.id === reportId);
};

export const getRatio = (a: number, b: number): number => {
  return b == 0 ? 0 : Number.parseFloat(((1 - a / b) * 100).toFixed(0));
};

export const getDirectionFromValue = (value: number | undefined | null): CardNumberRatioDirection => {
  if (value) {
    return value > 0 ? 'up' : 'down';
  }
  return 'none';
};

export const buildCostChartData = (
  reports: Record<string, UnitReportParsed[]>,
  unitReports: UnitEconomyReportDataResponse[],
  unitId: string | null,
): CostChartDataItems => {
  const data: CostChartDataItems = {
    freeMarket: [],
    conventionalMarket: [],
    economy: [],
  };

  Object.keys(reports).forEach((key) => {
    let freeMarket = 0,
      conventionalMarket = 0,
      economy = 0;
    const reportsByPeriod = reports[key];

    if (unitId) {
      const unitReport = reportsByPeriod.find((report) => {
        return report.unitId === unitId;
      });

      if (unitReport) {
        const report = getReportById(unitReport.reportId, unitReports);

        economy += report?.currentSavings ?? 0;
        freeMarket += report?.freeMarket.totalValue ?? 0;
        conventionalMarket += report?.conventionalMarket.totalValue ?? 0;
      }
    } else {
      reportsByPeriod.forEach((r) => {
        const report = getReportById(r.reportId, unitReports);

        freeMarket += report?.freeMarket.totalValue ?? 0;
        conventionalMarket += report?.conventionalMarket.totalValue ?? 0;
        economy += report?.currentSavings ?? 0;
      });
    }

    data.economy.push(economy);
    data.freeMarket.push(freeMarket);
    data.conventionalMarket.push(conventionalMarket);
  });

  return data;
};
