const LeafIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1384_28129)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.28253 24.8547C4.23319 22.6014 3.67319 19.7507 3.92253 17.204C4.19719 14.3947 5.47853 11.7 7.14653 10.3094C9.37586 8.45203 11.7172 7.68936 13.7492 7.31603C14.7388 7.14706 15.7353 7.02117 16.7359 6.9387C17.2745 6.88803 17.8199 6.83736 18.3439 6.7067C19.4479 6.4307 20.5372 5.9827 21.4345 5.2707C21.8452 4.94403 22.2612 4.61336 22.8145 4.6747C23.0134 4.69682 23.2048 4.76345 23.3745 4.86962C23.5441 4.9758 23.6877 5.1188 23.7945 5.28803C28.0612 12.044 27.4172 19.216 23.8532 23.7334C22.0732 25.988 19.5732 27.5614 16.6572 28.0267C14.1132 28.4307 11.3332 27.9787 8.53186 26.512C8.28573 27.2771 8.10289 28.061 7.98519 28.856C7.93515 29.2061 7.7481 29.522 7.46517 29.7342C7.18224 29.9463 6.82661 30.0374 6.47653 29.9874C6.12644 29.9373 5.81057 29.7503 5.5984 29.4673C5.38624 29.1844 5.29515 28.8288 5.34519 28.4787C5.51053 27.3254 5.82519 26.0974 6.28253 24.8547ZM14.2292 9.9387C15.1679 9.7667 16.0492 9.68403 16.9052 9.60003C17.6039 9.53336 18.3079 9.46403 18.9905 9.29336C20.1402 9.01168 21.2413 8.55956 22.2572 7.95203C25.1612 13.4414 24.3505 18.7974 21.7599 22.0827C20.3625 23.8534 18.4412 25.0427 16.2399 25.392C14.2625 25.7067 11.9732 25.36 9.53319 24.024C11.0159 21.0014 13.4399 18.104 16.5959 16.5267C16.9124 16.3686 17.1531 16.0913 17.2651 15.7557C17.3771 15.4202 17.3513 15.0539 17.1932 14.7374C17.0351 14.4209 16.7578 14.1801 16.4222 14.0681C16.0867 13.9561 15.7204 13.982 15.4039 14.14C11.9265 15.8787 9.26253 18.8854 7.53053 22.092C6.67586 20.692 6.41986 19.064 6.57719 17.4627C6.80253 15.1587 7.85719 13.1894 8.85319 12.3574C10.6239 10.8814 12.4905 10.2587 14.2305 9.9387H14.2292Z"
          fill="#00BF6A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1384_28129">
          <rect width="28" height="28" fill="white" transform="translate(0 0.666687)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeafIcon;
