import { useLazyQuery } from '@apollo/client';

import { QUERY_GET_CONSUMPTIONS_FROM_UNITS } from './queries';
import { UnitConsumptionReportGraphQL } from './types';

export const useConsumptionReportsByGroup = () => {
  const [getConsumptionsFromLast12Months, { data, loading, error }] = useLazyQuery<UnitConsumptionReportGraphQL>(
    QUERY_GET_CONSUMPTIONS_FROM_UNITS,
  );

  const getConsumptionsReportsHandler = (unitsIds: string[]) => {
    return getConsumptionsFromLast12Months({
      variables: { unitsIds: unitsIds },
    }).then((response) =>
      response.data?.last12MonthsRetroactiveByUnitIds.map(({ date, energyTotalConsumption }) => ({
        date,
        energyTotalConsumption,
      })),
    );
  };

  return {
    getConsumptionsReportsHandler,
    consumptionsData: data,
    loadingConsumptions: loading,
    error: error,
  };
};
