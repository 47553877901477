import React from 'react';

import { DEFAULT_CLASS_HIDDEN_ON_EXPORT } from '@hooks/export-as-image/constants';
import { SelectIcon } from '@components/atoms/form/select-icon';

import { populateOptions } from './functions';
import { ExportSelectProps, OptionsMap } from './types';

const exportsMap: OptionsMap = {
  png: {
    label: 'PNG',
    value: 'PNG',
  },
  excel: {
    label: 'Excel',
    value: 'EXCEL',
  },
};

const ExportSelect: React.FC<ExportSelectProps> = ({
  onChange,
  placeholder,
  availableExports = {
    png: true,
    excel: true,
  },
  exportIcon,
}) => {
  const options = populateOptions(availableExports, exportsMap);

  return (
    <span className={DEFAULT_CLASS_HIDDEN_ON_EXPORT}>
      <SelectIcon
        options={options}
        onChange={(val) => {
          onChange?.(val);
        }}
        value={''}
        placeholder={placeholder === null ? null : placeholder || 'Exportar'}
        prependIcon={exportIcon || 'ArrowDownTrayIcon'}
        appendIcon={null}
        bordered
      />
    </span>
  );
};

export default ExportSelect;
