import { EnergyMarketType, IEnergyMarketType } from './types';

export const getTooltipMessage = (marketType: IEnergyMarketType) => {
  return marketType === EnergyMarketType.ACL
    ? 'Esse é o valor da sua conta de luz no Mercado Livre, nele é possível ver o detalhamento da fatura de distribuição e de fornecimento.'
    : 'Simulamos como a sua conta de luz seria se estivesse no Mercado Cativo. A partir desse número chegamos quanto foi a economia.';
};

export const getMarketName = (marketType: IEnergyMarketType) => {
  return marketType === EnergyMarketType.ACL ? 'Livre' : 'Cativo';
};
