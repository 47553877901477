import numeral from 'numeral';
import { CardNumberRatioDirection, ColumnOptions } from '@clarke-energia/foton-v2';

import { $dayjs } from '@utils/dayjs';
import { formatNumber, formatCurrency, formatMonthAndYearDate } from '@utils/text';

import {
  UnitReportParsed,
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
  SummaryEconomyReportsHistory,
  UnitEconomyReportDataResponse,
  SummaryEconomyReportsHistoryParsed,
  UnitsAccumulatedSummaryEconomyReports,
  GroupAccumulatedEconomyReportByGroupId,
  GroupAccumulatedEconomyReportByGroupIdParsed,
  HistoryChartDataset,
  SustainabilityHistoryCharts,
  UnitAccumulatedEconomyReportByGroupId,
} from './types';

import { tableItemBold } from './function';
import { EnergyTypeEnum } from '@hooks/get-contracts/types';

const percentOption = {
  style: 'percent',
  maximumFractionDigits: 2,
};

const generalMarketColumnDefinitions: ColumnOptions[] = [
  { accessor: 'item', header: 'Item' },
  { accessor: 'quantity', header: 'Qtd.' },
  { accessor: 'tariff', header: 'Tarifa' },
  { accessor: 'total', header: 'Total' },
];

const generalMarketMinimalColumnDefinitions: ColumnOptions[] = [
  { accessor: 'item', header: 'Item' },
  { accessor: '1', header: '' },
  { accessor: '2', header: '' },
  { accessor: '3', header: '' },
  { accessor: '4', header: '' },
  { accessor: '5', header: '' },
  { accessor: 'total', header: 'Total' },
];

const getDirection = (value: number | undefined): CardNumberRatioDirection => {
  if (value) {
    return value > 0 ? 'up' : 'down';
  }
  return 'none';
};

export const freeMarketTablesGenerator = (report: UnitEconomyReportDataResponse) => {
  const freeMarketReport = report.freeMarket;

  const distributionReportData = freeMarketReport.energyDistribution;
  const distributionTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      ...(distributionReportData.billedDemandPeakAmount
        ? [
            {
              item: 'Demanda Ponta (kW)',
              quantity: formatNumber(distributionReportData.billedDemandPeakAmount),
              tariff: formatNumber(distributionReportData.billedDemandPeakCost),
              total: numeral(distributionReportData.billedDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Demanda Fora Ponta (kW)',
        quantity: formatNumber(distributionReportData.billedDemandOffPeakAmount),
        tariff: formatNumber(distributionReportData.billedDemandOffPeakCost),
        total: numeral(distributionReportData.billedDemandOffPeakTotal).format('$ 0,0.00'),
      },
      ...(distributionReportData.acrBilledDemandPeakTotal
        ? [
            {
              item: 'Demanda Ponta ACR (kW)',
              quantity: formatNumber(distributionReportData.acrBilledDemandPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandPeakCost),
              total: numeral(distributionReportData.acrBilledDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.acrBilledDemandOffPeakTotal
        ? [
            {
              item: 'Demanda Fora Ponta ACR (kW)',
              quantity: formatNumber(distributionReportData.acrBilledDemandOffPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandOffPeakCost),
              total: numeral(distributionReportData.acrBilledDemandOffPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'TUSD Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdPeakAmount),
        tariff: formatNumber(distributionReportData.tusdPeakCost),
        total: numeral(distributionReportData.tusdPeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TUSD Fora Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdOffPeakAmount),
        tariff: formatNumber(distributionReportData.tusdOffPeakCost),
        total: numeral(distributionReportData.tusdOffPeakTotal).format('$ 0,0.00'),
      },
      ...(distributionReportData.acrTePeakTotal
        ? [
            {
              item: 'TE Ponta ACR (MWh)',
              quantity: formatNumber(distributionReportData.acrBilledDemandPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandPeakCost),
              total: numeral(distributionReportData.acrBilledDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.acrTeOffPeakTotal
        ? [
            {
              item: 'TE Fora Ponta ACR (MWh)',
              quantity: formatNumber(distributionReportData.acrBilledDemandOffPeakAmount),
              tariff: formatNumber(distributionReportData.acrBilledDemandOffPeakCost),
              total: numeral(distributionReportData.acrBilledDemandOffPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.covidTariffTotal
        ? [
            {
              item: 'Encargo COVID-19 + Escassez Hídrica',
              quantity: formatNumber(distributionReportData.covidTariffAmount),
              tariff: formatNumber(distributionReportData.covidTariffCost),
              total: numeral(distributionReportData.covidTariffTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(distributionReportData.overtakingTotal
        ? [
            {
              item: 'Ultrapassagem',
              quantity: '-',
              tariff: '-',
              total: numeral(distributionReportData.overtakingTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Reativos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.reactivesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Impostos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.taxesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Outros',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.others).format('$ 0,0.00'),
      },
      {
        item: tableItemBold('Total Distribuição (c/ impostos)'),
        quantity: tableItemBold('-'),
        tariff: tableItemBold('-'),
        total: tableItemBold(numeral(distributionReportData.totalValue).format('$ 0,0.00')),
      },
    ],
    superheader: `Distribuição ${report.unit.edc.name ? `- ${report.unit.edc.name}` : ''}`,
  };

  const providedEnergyReportData = freeMarketReport.energyProvided;
  const shouldNotDeclareDevec = report.devecDeclaration === null;
  const providedEnergyTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      {
        item: report.trader.name,
        quantity: formatNumber(providedEnergyReportData.energyProvidedAmount, {
          style: 'decimal',
          useGrouping: true,
          maximumFractionDigits: 3,
        }),
        tariff: formatNumber(providedEnergyReportData.energyProvidedCost),
        total: numeral(providedEnergyReportData.energyProvidedTotal).format('$ 0,0.00'),
      },
      {
        item: 'Destaque PIS/COFINS',
        quantity: '-',
        tariff: formatNumber(providedEnergyReportData.highlightPisCofinsCost, percentOption),
        total: numeral(providedEnergyReportData.highlightPisCofinsTotal).format('$ 0,0.00'),
      },
      ...(providedEnergyReportData.energyProvidedAdjustmentValue
        ? [
            {
              item: 'Devoluções/Ajustes',
              quantity: '-',
              tariff: '-',
              total: numeral(providedEnergyReportData.energyProvidedAdjustmentValue).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(shouldNotDeclareDevec
        ? [
            {
              item: 'ICMS',
              quantity: '-',
              tariff: formatNumber(providedEnergyReportData.icmsCost, percentOption),
              total: numeral(providedEnergyReportData.icmsTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: tableItemBold('Total Fornecimento (c/ impostos)'),
        quantity: tableItemBold('-'),
        tariff: tableItemBold('-'),
        total: tableItemBold(numeral(providedEnergyReportData.totalValue).format('$ 0,0.00')),
      },
    ],
    superheader: 'Fornecimento',
  };

  const othersReportData = freeMarketReport.others;
  const othersTable: IGeneralReportTableData<IMinimalGeneralReportTable> = {
    columnDefinitions: generalMarketMinimalColumnDefinitions,
    data: [
      ...(othersReportData.eerCost
        ? [
            {
              item: 'EER',
              total: numeral(othersReportData.eerCost).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(othersReportData.essCost
        ? [
            {
              item: 'Liquidação Financeira + ESS',
              total: numeral(othersReportData.essCost).format('$ 0,0.00'),
            },
          ]
        : []),
      ...(othersReportData.cceeCost
        ? [
            {
              item: 'Contribuição Associativa',
              total: numeral(othersReportData.cceeCost).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Gestão Clarke',
        total: numeral(othersReportData.clarkeManagementCost).format('$ 0,0.00'),
      },
      {
        item: tableItemBold('Total Encargos'),
        total: tableItemBold(numeral(othersReportData.totalValue).format('$ 0,0.00')),
      },
    ],
    superheader: 'Encargos / Outros',
  };

  return [distributionTable, providedEnergyTable, othersTable];
};

export const conventionalMarketTablesGenerator = (report: UnitEconomyReportDataResponse) => {
  const conventionalMarketReport = report.conventionalMarket;

  const distributionReportData = conventionalMarketReport;
  const distributionTable: IGeneralReportTableData<IGeneralReportTable> = {
    columnDefinitions: generalMarketColumnDefinitions,
    data: [
      ...(distributionReportData.billedDemandPeakAmount
        ? [
            {
              item: 'Demanda Ponta (kW)',
              quantity: formatNumber(distributionReportData.billedDemandPeakAmount),
              tariff: formatNumber(distributionReportData.billedDemandPeakCost),
              total: numeral(distributionReportData.billedDemandPeakTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Demanda  Fora Ponta (kW)',
        quantity: formatNumber(distributionReportData.billedDemandOffPeakAmount),
        tariff: formatNumber(distributionReportData.billedDemandOffPeakCost),
        total: numeral(distributionReportData.billedDemandOffPeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TUSD Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdPeakAmount),
        tariff: formatNumber(distributionReportData.tusdPeakCost),
        total: numeral(distributionReportData.tusdPeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TUSD Fora Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tusdOffPeakAmount),
        tariff: formatNumber(distributionReportData.tusdOffPeakCost),
        total: numeral(distributionReportData.tusdOffPeakTotal).format('$ 0,0.00'),
      },

      {
        item: 'TE Ponta (MWh)',
        quantity: formatNumber(distributionReportData.tePeakAmount),
        tariff: formatNumber(distributionReportData.tePeakCost),
        total: numeral(distributionReportData.tePeakTotal).format('$ 0,0.00'),
      },
      {
        item: 'TE Fora Ponta (MWh)',
        quantity: formatNumber(distributionReportData.teOffPeakAmount),
        tariff: formatNumber(distributionReportData.teOffPeakCost),
        total: numeral(distributionReportData.teOffPeakTotal).format('$ 0,0.00'),
      },

      ...(distributionReportData.overtakingTotal
        ? [
            {
              item: 'Ultrapassagem',
              quantity: '-',
              tariff: '-',
              total: numeral(distributionReportData.overtakingTotal).format('$ 0,0.00'),
            },
          ]
        : []),
      {
        item: 'Reativos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.reactivesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Bandeira Tarifária',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.tariffFlagTotal).format('$ 0,0.00'),
      },
      {
        item: 'Impostos',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.taxesTotal).format('$ 0,0.00'),
      },
      {
        item: 'Outros',
        quantity: '-',
        tariff: '-',
        total: numeral(distributionReportData.others).format('$ 0,0.00'),
      },
      {
        item: tableItemBold('Total'),
        quantity: tableItemBold('-'),
        tariff: tableItemBold('-'),
        total: tableItemBold(numeral(distributionReportData.totalValue).format('$ 0,0.00')),
      },
    ],
    superheader: `Distribuição ${report.unit.edc.name ? `- ${report.unit.edc.name}` : ''}`,
  };

  return [distributionTable];
};

export const formatUnitsEconomyReports = (unitReports: UnitEconomyReportDataResponse[]): UnitReportParsed[] => {
  const unitReportsSortedByPeriod = unitReports.sort(
    (current, next) => $dayjs(current.date).unix() - $dayjs(next.date).unix(),
  );
  return unitReportsSortedByPeriod.map((unitReport) => {
    return {
      reportId: unitReport.id,
      unitName: unitReport.unit.name,
      unitId: unitReport.unit.id,
      date: unitReport.date,
      conventionalMarket: conventionalMarketTablesGenerator(unitReport),
      freeMarket: freeMarketTablesGenerator(unitReport),
      economy: {
        total: numeral(unitReport.currentSavings).format('$ 0,0.00'),
        percentage: numeral(unitReport.savingsPercent / 100).format('%0.00'),
        typePercentageStyle: getDirection(unitReport.savingsPercent),
      },
      energyConsumption: {
        total: `${numeral(unitReport.energyTotalConsumption || 0).format('0,0.00')} MWh`,
        percentageBalance: unitReport.energyConsumptionBalancePercent
          ? numeral(unitReport.energyConsumptionBalancePercent / 100).format('%0.00')
          : '',
        typePercentageStyle: getDirection(unitReport.energyConsumptionBalancePercent),
      },
      energyCost: {
        total: numeral(unitReport.energyTotalCost).format('$ 0,0.00'),
        percentageBalance: unitReport.energyConsumptionBalancePercent
          ? numeral(unitReport.energyConsumptionBalancePercent / 100).format('%0.00')
          : '',
        typePercentageStyle: getDirection(unitReport.energyConsumptionBalancePercent),
      },
    };
  });
};

export const formatUnitsAccumulatedSummaryEconomyReportsFromGroup = (
  unitsAccumulatedSummaryEconomyReports: UnitsAccumulatedSummaryEconomyReports[],
): UnitReportParsed[] => {
  return unitsAccumulatedSummaryEconomyReports.map((unitAccumulatedSummaryReport) => {
    const economyPerecentageSavgins = unitAccumulatedSummaryReport.accumulatedUnitsSavingsPercentage ?? 0;
    return {
      unitId: unitAccumulatedSummaryReport.unit.id,
      unitName: unitAccumulatedSummaryReport.unit.name,
      date: unitAccumulatedSummaryReport.date,
      economy: {
        total: numeral(unitAccumulatedSummaryReport.accumulatedUnitsSavings).format('$ 0,0.00'),
        percentage: numeral(economyPerecentageSavgins / 100).format('%0.00'),
        typePercentageStyle: getDirection(unitAccumulatedSummaryReport.accumulatedUnitsSavingsPercentage),
      },
      energyConsumption: {
        total: `${numeral(unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumption || 0).format('0,0.00')} MWh`,
        percentageBalance: unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercentage
          ? numeral(unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercentage / 100).format('%0.00')
          : '',
        typePercentageStyle: getDirection(unitAccumulatedSummaryReport.accumulatedUnitsEnergyConsumptionPercentage),
      },
      energyCost: {
        total: numeral(unitAccumulatedSummaryReport.accumulatedUnitsEnergyCost).format('$ 0,0.00'),
        percentageBalance: unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercentage
          ? numeral(unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercentage / 100).format('%0.00')
          : '',
        typePercentageStyle: getDirection(unitAccumulatedSummaryReport.accumulatedUnitsEnergyCostPercentage),
      },
    };
  });
};

export const formatSummaryEconomyReportsHistory = (
  summaryEconomyReportsHistory: SummaryEconomyReportsHistory[],
): SummaryEconomyReportsHistoryParsed[] => {
  return summaryEconomyReportsHistory.map((summaryEconomyReport) => ({
    id: summaryEconomyReport.id,
    date: formatMonthAndYearDate(summaryEconomyReport.date),
    monthlySavings: {
      total: numeral(summaryEconomyReport.monthlySavings).format('$ 0,0.00'),
      percentage: numeral(summaryEconomyReport.monthlySavingsPercentage / 100).format('%0.00'),
      typePercentageStyle: getDirection(summaryEconomyReport.monthlySavingsPercentage),
    },
    monthlyEnergyConsumption: {
      total: `${numeral(summaryEconomyReport.monthlyEnergyConsumption || 0).format('0,0.00')} MWh`,
      percentage: numeral(summaryEconomyReport.monthlyEnergyConsumptionPercentage / 100).format('%0.00'),
      typePercentageStyle: getDirection(summaryEconomyReport.monthlyEnergyConsumptionPercentage),
    },
    monthlyEnergyCost: {
      total: numeral(summaryEconomyReport.monthlyEnergyCost).format('$ 0,0.00'),
      percentage: numeral(summaryEconomyReport.monthlyEnergyCostPercentage / 100).format('%0.00'),
      typePercentageStyle: getDirection(summaryEconomyReport.monthlyEnergyCostPercentage),
    },
    monthlyCarbonDioxideEmissionAvoided: numeral(summaryEconomyReport.monthlyCarbonDioxideEmissionAvoided ?? 0).format(
      '0,0',
    ),
    monthlyTreesSaved: numeral(summaryEconomyReport.monthlyTreesSaved ?? 0).format('0,0'),
  }));
};

export const findActiveEnergyTypeFromContract = (
  contracts:
    | GroupAccumulatedEconomyReportByGroupId['group']['energyContracts']
    | UnitAccumulatedEconomyReportByGroupId['group']['energyContracts'],
): EnergyTypeEnum | string => {
  const activeContract = contracts.find(({ isActive }) => isActive);

  if (activeContract) {
    return EnergyTypeEnum[activeContract.energyType];
  }
  return '-';
};

export const parseGroupAccumulatedEconomyReport = (
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupId | UnitAccumulatedEconomyReportByGroupId,
): GroupAccumulatedEconomyReportByGroupIdParsed => {
  const accumulatedUnits =
    'unitsAccumulatedSummaryEconomyReports' in groupAccumulatedEconomyReport
      ? groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports ?? []
      : [];
  return {
    id: groupAccumulatedEconomyReport.id,
    groupId: groupAccumulatedEconomyReport.group.id,
    groupName: groupAccumulatedEconomyReport.group.name,
    totalCarbonDioxideEmissionAvoided: numeral(groupAccumulatedEconomyReport.totalCarbonDioxideEmissionAvoided).format(
      '0,0',
    ),
    totalEnergyConsumption: `${numeral(groupAccumulatedEconomyReport.totalEnergyConsumption || 0).format(
      '0,0.00',
    )} MWh`,
    totalEnergyCost: formatCurrency(groupAccumulatedEconomyReport.totalEnergyCost),
    totalSavings: formatCurrency(groupAccumulatedEconomyReport.totalSavings),
    totalSavingsPercentage: numeral(groupAccumulatedEconomyReport.totalSavingsPercentage / 100).format('%0.00'),
    totalEnergyConsumptionPercentage: numeral(
      groupAccumulatedEconomyReport.totalEnergyConsumptionPercentage / 100,
    ).format('%0.00'),
    totalEnergyCostPercentage: numeral(groupAccumulatedEconomyReport.totalEnergyCostPercentage / 100).format('%0.00'),
    totalTreesSaved: numeral(groupAccumulatedEconomyReport.totalTreesSaved ?? 0).format('0,0'),
    totalSavingsPercentageTagType: getDirection(groupAccumulatedEconomyReport.totalSavings),
    totalConsumptionPercentageTagType: getDirection(groupAccumulatedEconomyReport.totalEnergyConsumptionPercentage),
    totalCostPercentageTagType: getDirection(groupAccumulatedEconomyReport.totalEnergyCostPercentage),
    type: groupAccumulatedEconomyReport.type,
    unitsAccumulatedSummaryEconomyReports: formatUnitsAccumulatedSummaryEconomyReportsFromGroup(accumulatedUnits),
    summaryEconomyReportsHistory: formatSummaryEconomyReportsHistory(
      groupAccumulatedEconomyReport.summaryEconomyReportsHistory ?? [],
    ),
    createdAt: groupAccumulatedEconomyReport.createdAt,
    updatedAt: groupAccumulatedEconomyReport.updatedAt,
  };
};

export const parseSustainabilityReportCharts = (
  summaryEconomyReportsHistory: SummaryEconomyReportsHistory[],
): SustainabilityHistoryCharts => {
  const verifyHistoryValidity = (historyObject: HistoryChartDataset) => {
    return historyObject.values.filter((value) => value !== null).length > 0 ? historyObject : null;
  };

  // if the object is not copied, and your origin is apollo query, the "Uncaught TypeError: 0 is read-only javascript error" will be thrown
  const summaryEconomyReportHistoryCopy = [...summaryEconomyReportsHistory];

  const summaryReportsSortedByReferencePeriod = summaryEconomyReportHistoryCopy.sort(
    (current, next) => $dayjs(current.date).unix() - $dayjs(next.date).unix(),
  );

  const historyMonthsLabels = summaryReportsSortedByReferencePeriod.map((report) =>
    $dayjs(report.date, 'YYYY-MM-DD').format('MM/YY'),
  );

  const CO2AvoidedHistory: HistoryChartDataset = {
    labels: historyMonthsLabels,
    values: [],
  };

  const treesSavedHistory: HistoryChartDataset = {
    labels: historyMonthsLabels,
    values: [],
  };

  for (let i = 0; i < summaryReportsSortedByReferencePeriod.length; i++) {
    const report = summaryReportsSortedByReferencePeriod[i];

    CO2AvoidedHistory.values.push(report.monthlyCarbonDioxideEmissionAvoided ?? 0);
    treesSavedHistory.values.push(report.monthlyTreesSaved ?? 0);
  }

  return {
    CO2AvoidedHistory: verifyHistoryValidity(CO2AvoidedHistory),
    treesSavedHistory: verifyHistoryValidity(treesSavedHistory),
  };
};
