import dayjs from 'dayjs';

export const CLARKE_CUSTOMER_GROUP_ID = 'bf40ddd3-39d7-4860-8563-21d87dee2c1e';

export const getMonthYear = (actualMonth = 1) => {
  const rawDate = new Date();
  const pageMonth = (rawDate.getMonth() + actualMonth).toString();
  const year = rawDate.getFullYear().toString();
  return pageMonth.padStart(2, '0') + '/' + year;
};

export const getActualMonth = (groupId?: string): number => {
  return groupId === CLARKE_CUSTOMER_GROUP_ID ? 0 : 1;
};

export function transformDateToMonthYear(dateString: string) {
  const [year, month] = dateString.split('-');
  const formattedDate = `${month}/${year}`;
  return formattedDate;
}

export function transformMonthYearToDate(monthYear: string | undefined) {
  if (monthYear) {
    const date = dayjs(monthYear, 'MM/YYYY');
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  }
}
