import { CardNumberProps } from '@clarke-energia/foton-v2';
import { EconomyReportIconCardData, EconomyReportIconCardDataItem } from '@pages/economy-reports/types';

export const getRatio = (data: EconomyReportIconCardDataItem) => {
  return {
    value: data.percentage ?? '',
    direction: data.direction ?? 'none',
  };
};

export const buildCardNumberProps = (data: EconomyReportIconCardData): CardNumberProps[] => {
  return [
    {
      icon: {
        name: 'CurrencyDollarIcon',
      },
      title: 'Economia',
      value: data.economy.total,
      ratio: data.economy.direction ? getRatio(data.economy) : undefined,
      variant: 'compact',
      tooltip: data.economy.tooltip,
    },
    {
      icon: {
        name: 'BanknotesIcon',
      },
      title: 'Custo',
      value: data.energy.cost.total,
      ratio: data.energy.cost.direction ? getRatio(data.energy.cost) : undefined,
      variant: 'compact',
      tooltip: data.energy.cost.tooltip,
    },
    {
      icon: {
        name: 'LightBulbIcon',
      },
      title: 'Consumo',
      value: data.energy.consumption.total,
      ratio: data.energy.consumption.direction ? getRatio(data.energy.consumption) : undefined,
      variant: 'compact',
      tooltip: data.energy.consumption.tooltip,
    },
  ];
};
