import { BarChart } from '@clarke-energia/foton-v2';

import ChartContainer from '@components/molecules/charts/chart-container';

interface ConsumptionHistoryBarChartProps {
  title: string;
  labels: string[];
  values: number[];
  exportName: string;
  excelPath?: string;
}
const ConsumptionHistoryBarChart = ({
  title,
  labels,
  values,
  exportName,
  excelPath,
}: ConsumptionHistoryBarChartProps) => {
  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Histórico de consumo mensal"
      export={{
        name: exportName,
        excelPath: excelPath,
      }}
    >
      <BarChart
        labels={labels}
        series={[
          {
            name: 'Consumo realizado',
            color: '#00DF7C',
            data: values,
          },
        ]}
        options={{
          legend: { show: true },
          autoScale: true,
          suffix: ' MWh',
        }}
      />
    </ChartContainer>
  );
};

export default ConsumptionHistoryBarChart;
