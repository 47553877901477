import { LineChartProps } from '@clarke-energia/foton-v2';

export type PriceChartData = Pick<LineChartProps, 'series' | 'labels'>;

export enum EnergyTypeEnum {
  CONVENTIONAL = 'Convencional',
  INCENTIVIZED_0 = 'Incentivada 0%',
  INCENTIVIZED_50 = 'Incentivada 50%',
  INCENTIVIZED_80 = 'Incentivada 80%',
  INCENTIVIZED_100 = 'Incentivada 100%',
}

export enum GuaranteeTypeEnum {
  NO_GUARANTEE = 'Sem garantia',
  SURETY_BOND = 'Seguro garantia',
  ADVANCE_PAYMENT = 'Pagamento antecipado',
  BANK_GUARANTEE = 'Carta fiança bancária',
  GUARANTEE_LETTER = 'Nota promissória',
  REGISTRATION_AGAINST_PAYMENT = 'Registro contra pagamento',
  OTHER = 'Outro',
}

export enum ReadjustmentIndexEnum {
  IPCA = 'IPCA',
  IGPM = 'IGPM',
}
export enum ReadjustmentFrequencyEnum {
  EACH_12_MONTHS = 'A cada 12 meses',
  INIT_CONTRACT_PERIOD = 'Início de cada período de fornecimento',
}

export enum ContractTypeEnum {
  GUARANTEED_SAVING = 'Economia Garantida',
  FIXED_PRICE = 'Preço Fixo Escalonado',
}

interface Unit {
  name: string;
  id: string;
}

interface Trader {
  name: string;
}

interface Attachment {
  fileName: string;
  url: string;
}

interface YearValue {
  year: number;
  amount: number;
}

export interface EnergyContract {
  isActive: boolean;
  lowerFlexibility: number;
  upperFlexibility: number;
  startSupplyDate: string;
  endSupplyDate: string;
  energyType: keyof typeof EnergyTypeEnum;
  guaranteeType: keyof typeof GuaranteeTypeEnum;
  otherGuaranteeType?: string;
  trader: Trader;
  units: Unit[];
  fixedContractedVolume: number | null;
  contractedVolumePerYear: YearValue[];
  contractType: keyof typeof ContractTypeEnum;
  economy: YearValue[];
  energyContractAttachments: Attachment[];
  readjustmentBaseDate: string | null;
  readjustmentIndex: keyof typeof ReadjustmentIndexEnum | null;
}

export interface ManagementContract {
  clarkeMagmentAmount: number;
  isActive: boolean;
  readjustmentBaseDate: string | null;
  readjustmentIndex: keyof typeof ReadjustmentIndexEnum | null;
  signatureDate?: string;
  attachments: Attachment[];
  units: Unit[];
}

export interface ContractGroupGraphQL {
  group: {
    units: {
      id: string;
    }[];
    energyContracts: EnergyContract[];
    clarkeContracts: ManagementContract[];
  };
}

export interface Contract {
  type: 'ENERGY' | 'MANAGEMENT';
  active: boolean;
  period: string;
  unitsNames: string[];
  unitsIds: string[];
  contracted: string;
  guarantee?: string;
  energyType?: string;
  upperFlexibility?: number;
  lowerFlexibility?: number;
  flexibility?: string;
  readjustmentType?: string;
  readjustmentDate?: string;
  managementAmount?: string;
  file?: {
    name: string;
    link: string;
  };
  volumePerYear?: {
    year: number;
    amountMWm: number;
    amountMWh: number;
  }[];
  flexPerYear?: {
    year: number;
    upperFlex: number;
    lowerFlex: number;
  }[];
  prices?: { year: number; amount: string; rawAmount: number }[];
}
