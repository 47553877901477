import { Button } from '@clarke-energia/foton-v2';
import ecologicalSustainability from '@assets/ecological-sustainability.jpeg';

import NuggetInfo from './large-nugget';
import style from './style.module.css';

const SustainabilityCertificates: React.FC = () => {
  return (
    <>
      <div className={style.imageContainer}>
        <img className={style.image} src={ecologicalSustainability} />
      </div>

      <div className={style.contentContainer}>
        <p>
          Você faz parte de <strong>30%</strong> das empresas no mercado livre que consomem energia de fontes
          renováveis!
        </p>
        <p>
          As fontes de energia não renováveis impactam diretamente o meio ambiente com a emissão de gases do efeito
          estufa. Consumir energia no Mercado Livre através da Clarke Energia significa garantir que seu consumo seja
          proveniente de fontes renováveis, como biomassa, eólica, solar ou de Pequena Central Hidrelétrica (PCH).
        </p>

        <div className={style.nuggetsContainer}>
          {/* TODO: In foton-v2, nugget just with info (centr.) */}
          <NuggetInfo
            icon={{
              icon: 'CloudIcon',
              extraClassNames: style.icon,
            }}
            info="Biomassa"
          />
          <NuggetInfo
            icon={{
              icon: 'WindTurbineIcon',
              extraClassNames: style.icon,
            }}
            info="Eólica"
          />
          <NuggetInfo
            icon={{
              icon: 'SunIcon',
              extraClassNames: style.icon,
            }}
            info="Solar"
          />
          <NuggetInfo
            icon={{
              icon: 'RiverIcon',
              extraClassNames: style.icon,
            }}
            info="PCH"
          />
        </div>

        <div className={style.buttonsContainer}>
          <Button kind="secondary" size="big" leftIcon="LockClosedIcon" className="grow" disabled>
            Comprar certificado I-REC
          </Button>
          <Button kind="primary" size="big" leftIcon="LockClosedIcon" className="grow" disabled>
            Emitir certificado Clarke gratuito
          </Button>
        </div>
      </div>
    </>
  );
};

export default SustainabilityCertificates;
