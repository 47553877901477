import { IconName } from '@clarke-energia/foton-v2';
import {
  MIGRATION_REPORT_PATH,
  CONSUMPTION_DASHBOARD_PATH,
  SUSTAINABILITY_PATH,
  ECONOMY_REPORT_PATH,
  CONTRACTS_PATH,
  HOME_PATH,
} from '@routers/constants';

export const BASE_MENU_ITENS = [
  {
    links: [
      {
        label: 'Dashboard',
        path: HOME_PATH,
        iconName: 'SquaresPlusIcon',
      },
    ],
  },
  {
    sessionTitle: 'Menu',
    links: [
      {
        label: 'Relatório de economia',
        path: ECONOMY_REPORT_PATH,
        iconName: 'DocumentChartBarIcon' as IconName,
      },
      {
        label: 'Migração',
        path: MIGRATION_REPORT_PATH,
        iconName: 'ArrowsRightLeftIcon' as IconName,
      },
      {
        label: 'Consumo',
        path: CONSUMPTION_DASHBOARD_PATH,
        iconName: 'LightBulbIcon' as IconName,
      },
      {
        label: 'Sustentabilidade',
        path: `${SUSTAINABILITY_PATH}`,
        iconName: 'GlobeAmericasIcon' as IconName,
      },
      {
        label: 'Contratos',
        path: CONTRACTS_PATH,
        iconName: 'DocumentCheckIcon' as IconName,
      },
    ],
  },
];
