import React from 'react';
import { Select } from '@clarke-energia/foton-v2';
import { IUnitsReport } from '@hooks/migration-report/use-migration-report-by-group/types';

interface UnitFilterProps {
  units: IUnitsReport[];
  selectedUnitId: string;
  onChange: (unitId: string) => void;
}
const UnitMapOptions = (units: IUnitsReport[]) => {
  return units.map((unit) => ({ label: unit.nickname, value: unit.thirdPartyId }));
};
const UnitFilter: React.FC<UnitFilterProps> = ({ units, selectedUnitId, onChange }) => {
  return (
    <Select.Root>
      <Select.Input
        options={UnitMapOptions(units)}
        value={selectedUnitId}
        onChange={(value) => onChange(value)}
        prependIcon="AdjustmentsHorizontalIcon"
        appendIcon={null}
      />
    </Select.Root>
  );
};

export default UnitFilter;
