import { $dayjs } from '@utils/dayjs';
import { MigrationState } from '@hooks/migration-report/use-migration-report-by-group/types';

export const isOverdue = (dueDate: string | null) => {
  if (!dueDate) {
    return false;
  }
  const dueDateObj = $dayjs(dueDate);
  const today = $dayjs();
  return today.isAfter(dueDateObj, 'day');
};

export const getMigrationState = (progress: number, dueDate: string | null): MigrationState => {
  if (progress === 1) {
    return MigrationState.DONE;
  }
  return isOverdue(dueDate) ? MigrationState.OVERDUE : MigrationState.IN_PROGRESS;
};
