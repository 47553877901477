import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card } from '@clarke-energia/foton-v2';

const getThreeCards = (name: string) => {
  return Array(3)
    .fill({ w: '100%', h: '100%' })
    .map((skeleton, index) => {
      return (
        <Card.Root className="col-span-full lg:col-span-1" key={`generic-cards-skeleton-${name}-${index}`}>
          <Card.Content className="grid grid-cols-3 gap-5 h-full">
            <div className="col-span-full lg:col-span-1">
              <Skeleton className="rounded-medium" height={skeleton.h} width={skeleton.w} />
            </div>
            <div className="col-span-full lg:col-span-2">
              <Skeleton className="rounded-medium" height={skeleton.h} width={skeleton.w} />
            </div>
          </Card.Content>
        </Card.Root>
      );
    });
};

export const EconomyReportSkeleton: React.FC = () => (
  <div className="col-span-full w-full">
    <div className="grid grid-cols-3 w-full gap-5 mb-5 min-h-52">{getThreeCards('economy-cards')}</div>
    <div className="grid grid-cols-1 lg:grid-cols-3 w-full gap-5 mb-5 min-h-60">
      <Card.Root className="col-span-full md:col-span-1">
        <Card.Content className="h-full">
          <Skeleton className="rounded-medium" key={`economy-chart-skeleton`} height={'100%'} width={'100%'} />
        </Card.Content>
      </Card.Root>
      <Card.Root className="col-span-full md:col-span-2">
        <Card.Content className="h-full">
          <Skeleton className="rounded-medium" key={`cost-chart-skeleton`} height={'100%'} width={'100%'} />
        </Card.Content>
      </Card.Root>
    </div>
    <div className="grid grid-cols-3 w-full gap-5 min-h-52">{getThreeCards('unit-economy-cards')}</div>
  </div>
);
