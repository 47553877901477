import html2canvas from 'html2canvas';
import { DEFAULT_CLASS_HIDDEN_ON_EXPORT } from './constants';

const createImage = async ({
  element,
  classToHide,
  extraClassNames,
  scale = 3,
  backgroundColor = '#f2f2f2',
  padding = 20,
  styleSheet,
}: {
  element: HTMLElement;
  classToHide?: string;
  extraClassNames?: string;
  scale?: number;
  backgroundColor?: string;
  padding?: number;
  styleSheet?: string;
}): Promise<string> => {
  const canvas = await html2canvas(element, {
    scale,
    backgroundColor,
    ignoreElements: (el) => {
      let ignore = el.classList.contains(DEFAULT_CLASS_HIDDEN_ON_EXPORT);
      if (classToHide) {
        ignore = ignore || el.classList.contains(classToHide);
      }
      return ignore;
    },
    onclone(clonedDocument, element) {
      element.style.width = `${element.clientWidth + padding * 2}px`;
      element.style.height = `${element.clientHeight + padding * 2}px`;
      element.style.padding = '20px';

      if (!extraClassNames) {
        return;
      }
      extraClassNames.split(' ').forEach((className) => {
        element.classList.add(className);
      });

      if (styleSheet) {
        const style = clonedDocument.createElement('style');
        style.innerHTML = styleSheet;
        clonedDocument.head.appendChild(style);
      }
    },

    allowTaint: true,
  });

  return canvas.toDataURL('image/png');
};

export const exportElementAsImage = async (params: {
  element: HTMLElement;
  exportName: string;
  classToHide?: string;
  extraClassNames?: string;
  backgroundColor?: string;
  padding?: number;
  styleSheet?: string;
}) => {
  const imageDataUrl = await createImage({
    element: params.element,
    classToHide: params.classToHide,
    extraClassNames: params.extraClassNames,
    backgroundColor: params.backgroundColor,
    padding: params.padding,
    styleSheet: params.styleSheet,
  });

  const linkElement = document.createElement('a');
  linkElement.href = imageDataUrl;
  linkElement.download = params.exportName + '.png';
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};

export const useExportElementAsImage = () => {
  return exportElementAsImage;
};
