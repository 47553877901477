import React from 'react';
import { Card } from '@clarke-energia/foton-v2';
import Skeleton from 'react-loading-skeleton';

export const HubSkeleton: React.FC = () => (
  <div className="col-span-full w-full">
    <div className="grid grid-cols-3 gap-5 mb-5 min-[]:h-52">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((_, index) => (
          <Card.Root className="col-span-full md:col-span-1" key={`hub-skeleton-first-${index}`}>
            <Card.Content>
              <Skeleton className="rounded-medium" width="100%" height="100%" />
              <Skeleton className="rounded-medium" width="100%" height="100%" />
              <Skeleton className="rounded-medium" width="100%" height="100%" />
            </Card.Content>
          </Card.Root>
        ))}
    </div>
    <div className="grid grid-cols-3 gap-5 h-52 mb-5">
      <Card.Root className="col-span-full md:col-span-1">
        <Card.Content className="h-full">
          <Skeleton className="rounded-medium" width="100%" height="100%" />
        </Card.Content>
      </Card.Root>
      <Card.Root className="col-span-full md:col-span-2">
        <Card.Content className="h-full">
          <Skeleton className="rounded-medium" width="100%" height="100%" />
        </Card.Content>
      </Card.Root>
    </div>
    <div className="grid grid-cols-3 gap-5 h-52 mb-5">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Card.Root className="col-span-full md:col-span-1" key={`proposal-skeleton-${index}`}>
            <Card.Content className="h-full">
              <Skeleton className="rounded-medium" width={skeleton.w} height={skeleton.h} />
            </Card.Content>
          </Card.Root>
        ))}
    </div>
  </div>
);
