import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '@components/templates/screen-layout';
import ClarkeLogo from '@assets/clarke-logo-horizontal.svg';
import ClarkeLogoMobile from '@assets/clarke-logo-light.svg';

import { useGetMigrationReportByGroup } from '@hooks/migration-report/use-migration-report-by-group';
import { useLastEconomyReportByGroup } from '@hooks/get-last-economy-report/use-last-economy-reports';
import { useUserInfo } from '@hooks/use-user-info';

import ExportButton from '@components/molecules/button/export-button';
import Icon from '@components/molecules/dashboard/icon';
import Migration from '@components/organisms/dashboard/migration';
import Economy from '@components/organisms/dashboard/economy';

const Dashboard: React.FC = () => {
  const [unitId, setUnitId] = useState<string>('');
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const navigate = useNavigate();
  const exportRef = useRef<HTMLDivElement>(null);
  const { user } = useUserInfo();
  const { data, loading, getLastGroupEconomyReport, getLastUnitEconomyReport, getLastAccumulatedReport, error } =
    useLastEconomyReportByGroup();

  const {
    data: migrationData,
    loading: migrationLoading,
    getMigrationReportByGroupHandler,
  } = useGetMigrationReportByGroup();

  React.useEffect(() => {
    if (user.group?.id) {
      const groupId = user.group?.id;

      if (error || !data.totalCosts.labels.length) {
        getMigrationReportByGroupHandler(groupId);
      }

      getLastAccumulatedReport({
        variables: {
          groupId,
        },
      });

      if (user.group.units.length === 1) {
        const unitId = user.group.units[0].id;
        setUnitId(unitId);

        getLastUnitEconomyReport({
          variables: {
            unitId,
          },
        });
        return;
      }

      getLastGroupEconomyReport({
        variables: {
          groupId,
        },
      });
    }
  }, [user, data.totalCosts]);

  React.useEffect(() => {
    const economyLoad = !data.date && !migrationData;

    if (!user.id || loading || migrationLoading || economyLoad) {
      setLoadingData(true);
    } else {
      setLoadingData(false);
    }
  }, [user, loading, migrationLoading, data.date, migrationData]);

  return (
    <Layout.Content
      dashboardStyle={{
        children: (
          <>
            <Icon src={ClarkeLogo} className="hidden lg:block" />
            <Icon src={ClarkeLogoMobile} className="block lg:hidden" />
          </>
        ),
      }}
      header={{
        title: '',
        displayDesktop: false,
        additionalButtons: (
          <ExportButton
            className="lg:mr-0"
            exportRef={exportRef}
            exportName="dashboard"
            kind="secondary"
            onExportExtraClassnames="bg-greenGradient"
            exportStyleSheet=".dashboard-gauge-chart { background-color: #bef4c1; opacity: 0.7; }"
          />
        ),
      }}
    >
      <div ref={exportRef}>
        {migrationData && !data.totalCosts.labels.length ? (
          <Migration
            loading={loadingData}
            navigate={navigate}
            totalMigrationPercentage={migrationData?.getMigrationReportByGroupId?.conclusionPercentage}
          />
        ) : (
          <Economy data={data} loading={loadingData} navigate={navigate} unitId={unitId} />
        )}
      </div>
    </Layout.Content>
  );
};

export default Dashboard;
