import { NuggetInfo } from '@clarke-energia/foton-v2';

import LeafIcon from '@components/atoms/icons/LeafIcon';
import TreeIcon from '@components/atoms/icons/TreeIcon';
import LightIcon from '@components/atoms/icons/LightIcon';

import { EnergyTypeEnum } from '@hooks/get-contracts/types';
import style from './style.module.css';

interface SustainabilityNuggetsProps {
  totalCo2Avoided: string;
  totalTreesSaved: string;
  energyType: EnergyTypeEnum | string;
}

const SustainabilityNuggets: React.FC<SustainabilityNuggetsProps> = (props: SustainabilityNuggetsProps) => {
  return (
    <div className={style.container}>
      {[
        {
          title: 'Total de CO² evitado (t)',
          info: props.totalCo2Avoided,
          icon: (
            <div className="w-7">
              <LeafIcon />
            </div>
          ),
        },
        {
          title: 'Equivalente em árvores (un)',
          info: props.totalTreesSaved,
          icon: (
            <div className="w-7">
              <TreeIcon />
            </div>
          ),
        },
        {
          title: 'Tipo de energia',
          info: props.energyType,
          icon: (
            <div className="w-7">
              <LightIcon />
            </div>
          ),
        },
      ].map((data) => (
        <div className={style.nuggetContainer} key={data.title}>
          <NuggetInfo
            title={data.title}
            info={data.info}
            icon={<span className={style.icon}>{data.icon}</span>}
            strongInfo
            sizes={{
              title: 14,
              info: 24,
              contentSpace: 10,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default SustainabilityNuggets;
