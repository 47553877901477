import React, { forwardRef } from 'react';
import { BarChart, Card, FabButton } from '@clarke-energia/foton-v2';

import { EconomyBarChartsProps } from './types';

import ExportSelect from '@components/molecules/export-select/default';
import EconomyChart from '@components/molecules/economy-report/economy-chart';

import style from './style.module.css';
import { DEFAULT_CLASS_HIDDEN_ON_EXPORT } from '@hooks/export-as-image/constants';

const InnerEconomyChart = forwardRef<HTMLDivElement, EconomyBarChartsProps['economyChart']>(function InnerEconomyChart(
  { title, onChange, freeMarket, conventionalMarket, economy },
  ref,
) {
  return (
    <div ref={ref} className={style.economyChart}>
      <Card.Root>
        <Card.Header className={style.cardHeader}>
          <Card.Title title={title} />
          <FabButton
            icon="ArrowDownTrayIcon"
            kind="secondary"
            label="Exportar"
            onClick={() => {
              onChange && onChange('PNG');
            }}
            className={DEFAULT_CLASS_HIDDEN_ON_EXPORT}
          />
        </Card.Header>
        <EconomyChart conventionalMarket={conventionalMarket} freeMarket={freeMarket} economy={economy} />
      </Card.Root>
    </div>
  );
});

const InnerCostChart = forwardRef<HTMLDivElement, EconomyBarChartsProps['costChart']>(function InnerCostChart(
  { title, onChange, labels, series, lineSeries },
  ref,
) {
  return (
    <div ref={ref} className={style.fullEconomyBarChart}>
      <Card.Root className="h-full">
        <Card.Header className={style.cardHeader}>
          <Card.Title title={title ?? ''} />
          <ExportSelect onChange={onChange} />
        </Card.Header>
        <BarChart
          labels={labels}
          series={series}
          options={{ legend: { show: true }, rightSpace: 16, leftSpace: 48 }}
          lineSeries={lineSeries}
        />
      </Card.Root>
    </div>
  );
});

const EconomyBarCharts: React.FC<EconomyBarChartsProps> = ({ economyChart, costChart, ...props }) => {
  return (
    <div {...props}>
      <InnerEconomyChart {...economyChart} ref={economyChart.ref} />
      <InnerCostChart {...costChart} ref={costChart.ref} />
    </div>
  );
};

export default EconomyBarCharts;
