import { HeroIcon } from '@clarke-energia/foton-v2';

import style from './style.module.css';

const DemandCard = (props: { idealDemand?: number }) => {
  return (
    <div className={style.container}>
      <div>
        <div className={style.icon}>
          <HeroIcon icon="ShieldCheckIcon" extraClassNames="text-[#00BF6A] h-[70%]" />
        </div>

        <div>
          <div>
            <p className="text-[14px]">Demanda ideal</p>
            <p className="text-[10px]">com base no histórico dos últimos 12 meses</p>
          </div>

          <p className={style.demand}>{props.idealDemand + ' kW'}</p>
        </div>
      </div>
    </div>
  );
};

export default DemandCard;
