const downloadExcelFile = (fileUrl: string, fileName: string, options?: RequestInit) => {
  fetch(fileUrl, options)
    .then((response) => {
      const { status } = response;
      if (status === 404) {
        throw new Error('Excel not available');
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      throw new Error('Error fetching the file:', error);
    });
};

const directDownload = (fileUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const useDownloadFiles = () => {
  return {
    downloadExcelFile,
    directDownload,
  };
};
