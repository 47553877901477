import React, { HTMLAttributes } from 'react';
import { CardNumber } from '@clarke-energia/foton-v2';

import { buildCardNumberProps } from './functions';
import { EconomyReportIconCardData } from '@pages/economy-reports/types';

export interface EconomyCardsProps extends HTMLAttributes<HTMLDivElement> {
  economyReportIconCardData: EconomyReportIconCardData;
}

const EconomyCards: React.FC<EconomyCardsProps> = ({ economyReportIconCardData, ...props }) => {
  return (
    <div {...props}>
      {buildCardNumberProps(economyReportIconCardData).map((item, index) => {
        return (
          <CardNumber
            key={`iconCard-${index}`}
            title={item.title}
            value={item.value ?? '-'}
            variant="compact"
            icon={item.icon}
            ratio={item.ratio}
            tooltip={item.tooltip}
          />
        );
      })}
    </div>
  );
};

export default EconomyCards;
