import { useEffect, useMemo, useState } from 'react';

import { Select } from '@clarke-energia/foton-v2';

import configuration from '@config';

import ConsumptionDemandChart from '@components/organisms/consumption/consumption-demand-chart';
import FinesWithReactivesChart from '@components/organisms/consumption/fines-with-reactives-chart';
import ConsumptionHistoryByUnitChart from '@components/organisms/consumption/consumption-history-chart-by-unit';
import GroupConsumptionHistoryBarChart from '@components/organisms/consumption/group-consumption-history-bar-chart';

import { SimpleConsumption } from '@contexts/consumption';

import { useUserInfo } from '@hooks/use-user-info';
import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { useUnitsSimpleEconomyReports } from '@hooks/economy/get-units-reports';
import { UnitConsumption } from '@hooks/consumption/use-units-consumptions-by-group/types';
import { useAdjustedDemandSimulator } from '@hooks/consumption/use-adjusted-demand-simulator';

import { getDemandRequestData, sortReportsByDate } from '../helper';

import DemandCard from './demand-card';

import style from './style.module.css';

interface AnnualConsumptionByGroupProps {
  unitConsumptions: UnitConsumption[];
  groupConsumptions: SimpleConsumption[];
}

// TODO: revise this before validation
const AnnualConsumptionByGroup: React.FC<AnnualConsumptionByGroupProps> = (props: AnnualConsumptionByGroupProps) => {
  const { user } = useUserInfo();

  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [unitReports, setUnitReports] = useState<UnitEconomyReport[]>([]);

  const { data: unitEconomyReports } = useUnitsSimpleEconomyReports();

  const { getAdjustedDemandSimulator, idealDemandPeak, idealDemandOffPeak } = useAdjustedDemandSimulator();

  useEffect(() => {
    if (selectedUnit) {
      const reports = sortReportsByDate(
        unitEconomyReports?.filter((report) => {
          return report.unit.id == selectedUnit;
        }) ?? [],
      );

      setUnitReports(reports);

      const demandRequestData = getDemandRequestData(reports.slice(-12));
      if (demandRequestData) {
        getAdjustedDemandSimulator(demandRequestData);
      }
    } else {
      setUnitReports([]);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (!unitEconomyReports || unitEconomyReports.length == 0) return;

    if (!selectedUnit) {
      const unit = unitEconomyReports[0].unit;
      setSelectedUnit(unit.id);
    }
  }, [unitEconomyReports]);

  const units = useMemo(() => {
    if (!unitEconomyReports || unitEconomyReports.length == 0) return [];
    const units: UnitEconomyReport['unit'][] = [];
    unitEconomyReports.forEach((report) => {
      !units.includes(report.unit) && units.push(report.unit);
    });
    return units;
  }, [unitEconomyReports]);

  const getUnit = (id: string) => {
    const unit = units.find((unit) => unit.id === id);
    if (!unit)
      return {
        name: '',
        id: '',
        contractedDemandOffPeak: 0,
      };
    return unit;
  };

  const groupExcelPath = useMemo(() => {
    return `${configuration.CCEE_API_HOST}/api/v1/group_consumption_excel/${user.group?.id}`;
  }, [user]);

  return (
    <div>
      <div className={style.container}>
        <div className={style.fullChartContainer}>
          <GroupConsumptionHistoryBarChart consumptions={props.groupConsumptions} excelPath={groupExcelPath} />
        </div>
        <div className={style.fullChartContainer}>
          <ConsumptionHistoryByUnitChart
            title="Histórico de Consumo por UC"
            consumptions={props.unitConsumptions}
            excelPath={`${groupExcelPath}/unit`}
          />
        </div>
      </div>
      <hr className="my-7" />
      <div className={style.lowerContainer}>
        <div className="col-span-full">
          <div className={style.lowerHeaderContainer}>
            <h4 className={style.lowerHeaderTitle}>Informações por unidade consumidora</h4>
            <div className={style.lowerUnitSelect}>
              <Select.Root>
                <Select.Input
                  options={units.map((unit) => ({ label: unit.name, value: unit.id }))}
                  value={selectedUnit || (units.length ? units[0].id : '')}
                  onChange={(value) => setSelectedUnit(value)}
                />
              </Select.Root>
            </div>
          </div>
        </div>
        <div className={style.lowerChartsContainer}>
          <div className={style.demandContainer}>
            <DemandCard idealDemand={idealDemandPeak || idealDemandOffPeak} />
          </div>
          <div className={style.lowerCharts}>
            <div>
              <ConsumptionDemandChart
                title={`Demanda Lida x Contratada - ${getUnit(selectedUnit).name}`}
                contractedDemand={getUnit(selectedUnit).contractedDemandOffPeak}
                demands={(unitEconomyReports || [])
                  .filter((report) => report.unit.id === selectedUnit)
                  .map((report) => {
                    return {
                      date: report.date,
                      value: report.freeMarket.readDemandOffPeak,
                    };
                  })}
              />
            </div>
            <div>
              <FinesWithReactivesChart
                title={`Multas de reativos - ${getUnit(selectedUnit).name}`}
                reports={unitReports}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualConsumptionByGroup;
