import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import PrivateRoute from './private-route';

import {
  HOME_PATH,
  MIGRATION_REPORT_PATH,
  CONSUMPTION_DASHBOARD_PATH,
  SUSTAINABILITY_PATH,
  CONTRACTS_PATH,
  ECONOMY_REPORT_PATH,
} from './constants';

import { UnitEconomyProvider } from '@contexts/units-economy';
import { NotificationsProvider } from '@contexts/notifications';

import NotFound404 from '@pages/404';
import Dashboard from '@pages/dashboard';
import Contracts from '@pages/contracts';

import ConsumptionProvider from '@contexts/consumption';
import ConsumptionHub from '@pages/consumption/hub';
import { UserInfoProvider } from '@contexts/users';
import SustainabilityHub from '@pages/sustainability/hub';
import MigrationHub from '@pages/migration-reports/hub';
import EconomyReport from '@pages/economy-reports';
import UnitsByState from '@pages/migration-reports/units-by-state';
import UnitMigrationDetails from '@pages/migration-reports/unit-migration-details';
import ScreenLayout from '@components/templates/screen-layout';

const MainRouter: React.FC = () => {
  return (
    <Routes>
      {/* ----------- Private routes ----------- */}
      <Route
        element={
          <PrivateRoute>
            <UserInfoProvider>
              <NotificationsProvider>
                <ScreenLayout.Root>
                  <Outlet />
                </ScreenLayout.Root>
              </NotificationsProvider>
            </UserInfoProvider>
          </PrivateRoute>
        }
      >
        <Route path={HOME_PATH} element={<Dashboard />} />
        <Route path={`${ECONOMY_REPORT_PATH}`} element={<EconomyReport />} />
        <Route path={`${ECONOMY_REPORT_PATH}/:id`} element={<EconomyReport />} />
        <Route path={`${ECONOMY_REPORT_PATH}/:id/:period`} element={<EconomyReport />} />
        <Route
          path={MIGRATION_REPORT_PATH}
          element={
            <UnitEconomyProvider>
              <MigrationHub />
            </UnitEconomyProvider>
          }
        />
        <Route
          path={`${MIGRATION_REPORT_PATH}/status/:state`}
          element={
            <UnitEconomyProvider>
              <UnitsByState />
            </UnitEconomyProvider>
          }
        />
        <Route path={`${MIGRATION_REPORT_PATH}/:unitReportId`} element={<UnitMigrationDetails />} />
        <Route
          path={`${SUSTAINABILITY_PATH}`}
          element={
            <UnitEconomyProvider>
              <SustainabilityHub />
            </UnitEconomyProvider>
          }
        />
        <Route
          path={CONSUMPTION_DASHBOARD_PATH}
          element={
            <ConsumptionProvider>
              <ConsumptionHub />
            </ConsumptionProvider>
          }
        />

        <Route path={CONTRACTS_PATH} element={<Contracts />} />
      </Route>
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default MainRouter;
