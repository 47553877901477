import { twMerge } from 'tailwind-merge';
import { HTMLAttributes } from 'react';

import { SelectOption } from '@clarke-energia/foton-v2';

import { SelectIcon } from '@components/atoms/form/select-icon';
import style from './style.module.css';

export interface EconomyFilterOption {
  options: SelectOption[];
  onChange?: (value: string) => void;
  value?: string;
  emptyValue?: string;
}

export interface EconomyFiltersProps extends HTMLAttributes<HTMLDivElement> {
  units: EconomyFilterOption;
  periods: EconomyFilterOption;
}

const EconomyFilters: React.FC<EconomyFiltersProps> = ({ units, periods, ...props }: EconomyFiltersProps) => {
  return (
    <div {...props} className={twMerge(style.container, props.className)}>
      <div className={style.selectItem}>
        <SelectIcon
          prependIcon="BuildingOfficeIcon"
          options={units.options}
          placeholder="Selecione uma unidade"
          onChange={units.onChange}
          value={units.value}
          emptyValue={units.emptyValue}
        />
      </div>
      <div className={style.selectItem}>
        <SelectIcon
          prependIcon="CalendarDaysIcon"
          options={periods.options}
          placeholder="Selecione um período"
          onChange={periods.onChange}
          value={periods.value}
          emptyValue={periods.emptyValue}
        />
      </div>
    </div>
  );
};

export default EconomyFilters;
