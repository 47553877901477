import { useState } from 'react';

import { AreaChart, AreaChartProps } from '@clarke-energia/foton-v2';

import { SelectIcon } from '@components/atoms/form/select-icon';
import ChartContainer from '@components/molecules/charts/chart-container';

import { DatetimeMeasurement, GroupDatetimeMeasurement } from '@contexts/consumption';
import { DatetimeMeasurement as ConsolidatedMeasurement } from '@hooks/consumption/use-get-consolidated-measurement-by-id/types';

import { $dayjs as dayjs } from '@utils/dayjs';

export interface HourConsumptionChartProps {
  title: string;
  exportName: string;
  excelPath?: string;
  days: string[];
  defaultDay?: string;
  // TODO: unify these typing somewhere in the code
  consolidatedMeasures: GroupDatetimeMeasurement[] | DatetimeMeasurement[] | ConsolidatedMeasurement[];
  onChangeDay?: (day: string) => void;
}

const parseChartData = (
  consolidatedMeasures: GroupDatetimeMeasurement[],
): {
  labels: AreaChartProps['labels'];
  series: AreaChartProps['series'];
} => {
  const schedules = Array.from(
    new Set(consolidatedMeasures.map((measure) => dayjs(measure.datetime).format('HH') + 'h')),
  );

  // TODO: fix this when increase new hooks
  // some reason, the context return 48 values of measures, and repeat two times the same datetime with different values
  // this some is base in https://github.com/clarke-energia/clarke-customer-frontend/pull/301
  // src/pages/consumption/consumption-hub.tsx::createGroupLineChartValues
  const consumptionsBySchedule = consolidatedMeasures.reduce((acc: Record<string, number>, measure) => {
    const schedule = measure.datetime;
    acc[schedule] = (acc[schedule] || 0) + parseFloat(measure.activeConsumption);
    return acc;
  }, {} as Record<string, number>);

  return {
    labels: schedules,
    series: [
      {
        values: Object.values(consumptionsBySchedule),
        area: true,
        name: 'Consumo realizado',
      },
    ],
  };
};

const HourConsumptionChart = ({
  title,
  exportName,
  days,
  consolidatedMeasures,
  defaultDay,
  onChangeDay,
  excelPath,
}: HourConsumptionChartProps) => {
  const [selectedDay, setSelectedDay] = useState(defaultDay);
  const { labels, series } = parseChartData(consolidatedMeasures as GroupDatetimeMeasurement[]);
  return (
    <ChartContainer
      title={{
        text: title,
      }}
      className=""
      tooltipContent="Consumo médio por hora"
      extraHeaderComponent={
        <div className="flex justify-end w-full">
          <SelectIcon
            options={days.map((day) => ({
              label: day,
              value: day,
            }))}
            onChange={(value) => {
              onChangeDay && onChangeDay(value);
              setSelectedDay(value);
            }}
            value={selectedDay}
            bordered
          />
        </div>
      }
      export={{
        name: exportName + '-' + selectedDay?.split('/').join('-'),
        excelPath: excelPath,
      }}
    >
      <AreaChart
        labels={labels}
        lines={[]}
        series={series}
        options={{
          legend: { show: true },
          autoScale: true,
          suffix: ' MWh',
        }}
      />
    </ChartContainer>
  );
};

export default HourConsumptionChart;
