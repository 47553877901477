import numeral from 'numeral';

import { $dayjs, formatDateFromString } from '@utils/dayjs';

import {
  Contract,
  EnergyContract,
  EnergyTypeEnum,
  GuaranteeTypeEnum,
  ManagementContract,
  ReadjustmentIndexEnum,
} from './types';
import { upperFirstLetter } from '@utils/text';

const DEFAULT_INPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const OUTPUT_FORMAT = 'MM/YYYY';
const LONG_MONTH_NAME_OUTPUT_FORMAT = 'MMMM/YYYY';

const getHoursOfYear = (initialYear: number, finalYear: number) => {
  const startOfYear = $dayjs(`${initialYear}-01-01`);
  const endOfYear = $dayjs(`${finalYear}-01-01`);

  const totalHours = endOfYear.diff(startOfYear, 'hour');

  return totalHours / 12;
};

export const formatEnergyContracts = (energyContracts: EnergyContract[]): Contract[] => {
  return energyContracts.map((contract) => {
    const initialPeriodStr = formatDateFromString(contract.startSupplyDate, DEFAULT_INPUT_FORMAT, OUTPUT_FORMAT);
    const finalPeriodStr = formatDateFromString(contract.endSupplyDate, DEFAULT_INPUT_FORMAT, OUTPUT_FORMAT);
    const readjustmentBaseDateStr = contract.readjustmentBaseDate
      ? formatDateFromString(contract.readjustmentBaseDate, DEFAULT_INPUT_FORMAT, LONG_MONTH_NAME_OUTPUT_FORMAT)
      : '';

    const upperFlex = contract.upperFlexibility / 100;
    const lowerFlex = contract.lowerFlexibility / 100;

    const volumePerYear = contract.fixedContractedVolume
      ? formatFixedVolumePerContractYear(contract.fixedContractedVolume, contract.economy)
      : formatPerContractYear(contract.contractedVolumePerYear);

    return {
      type: 'ENERGY',
      active: contract.isActive,
      period: `${initialPeriodStr} - ${finalPeriodStr}`,
      contracted: contract.trader.name,
      unitsNames: contract.units.map(({ name }) => name),
      unitsIds: contract.units.map(({ id }) => id),
      energyType: EnergyTypeEnum[contract.energyType],
      prices: formatPrices(contract.economy, contract.contractType),
      upperFlexibility: upperFlex,
      lowerFlexibility: lowerFlex,
      flexibility: formatFlexibilityStr(contract.upperFlexibility, contract.lowerFlexibility),
      readjustmentDate: upperFirstLetter(readjustmentBaseDateStr),
      readjustmentType: contract.readjustmentIndex ? ReadjustmentIndexEnum[contract.readjustmentIndex] : '',
      guarantee:
        contract.guaranteeType !== 'OTHER' ? GuaranteeTypeEnum[contract.guaranteeType] : contract.otherGuaranteeType,
      volumePerYear: volumePerYear,
      flexPerYear: calcFlexPerYear(volumePerYear, upperFlex, lowerFlex),
      file: contract.energyContractAttachments.length
        ? {
            name: contract.energyContractAttachments[0].fileName,
            link: contract.energyContractAttachments[0].url,
          }
        : undefined,
    };
  });
};

export const formatManagementContracts = (managementContracts: ManagementContract[]): Contract[] => {
  return managementContracts.map((contract) => {
    return {
      type: 'MANAGEMENT',
      active: contract.isActive,
      contracted: 'Clarke',
      unitsNames: contract.units.map(({ name }) => name),
      unitsIds: contract.units.map(({ id }) => id),
      readjustmentDate: contract.readjustmentBaseDate
        ? upperFirstLetter(
            formatDateFromString(contract.readjustmentBaseDate, DEFAULT_INPUT_FORMAT, LONG_MONTH_NAME_OUTPUT_FORMAT),
          )
        : '',
      readjustmentType: contract.readjustmentIndex ? ReadjustmentIndexEnum[contract.readjustmentIndex] : '',
      file: contract.attachments.length
        ? {
            name: contract.attachments[0].fileName,
            link: contract.attachments[0].url,
          }
        : undefined,
      period: contract.signatureDate
        ? formatDateFromString(contract.signatureDate, DEFAULT_INPUT_FORMAT, OUTPUT_FORMAT)
        : '',
      managementAmount: numeral(contract.clarkeMagmentAmount).format('$ 0,0.00'),
    };
  });
};

const formatFlexibilityStr = (upper: number, lower: number) => {
  const upperPercentage = numeral(upper / 100).format('0%');
  const lowerPercentage = numeral(lower / 100).format('0%');
  if (upper == lower) return `+/-${upperPercentage}`;

  return `+${upperPercentage} / -${lowerPercentage}`;
};

const formatPrices = (economy: EnergyContract['economy'], contractType: EnergyContract['contractType']) => {
  return economy.map(({ year, amount }) => ({
    year,
    amount: contractType === 'FIXED_PRICE' ? numeral(amount).format('$ 0,0.00') : numeral(amount / 100).format('0%'),
    rawAmount: amount,
  }));
};

const formatFixedVolumePerContractYear = (
  fixedVolume: number,
  economy: EnergyContract['economy'],
): Contract['volumePerYear'] =>
  economy.map(({ year }) => ({
    year,
    amountMWm: fixedVolume,
    amountMWh: getHoursOfYear(year, year + 1) * fixedVolume,
  }));

const formatPerContractYear = (volumePerYear: EnergyContract['contractedVolumePerYear']): Contract['volumePerYear'] =>
  volumePerYear.map(({ year, amount }) => ({
    year,
    amountMWm: amount,
    amountMWh: getHoursOfYear(year, year + 1) * amount,
  }));

const calcFlexPerYear = (
  volumePerYear: Contract['volumePerYear'],
  upperFlex: number,
  lowerFlex: number,
): Contract['flexPerYear'] =>
  volumePerYear &&
  volumePerYear.map(({ year, amountMWh }) => ({
    year,
    lowerFlex: amountMWh * (1 - lowerFlex),
    upperFlex: amountMWh * (1 + upperFlex),
  }));
