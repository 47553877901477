import { gql } from '@apollo/client';

export const QUERY_GET_CONSUMPTIONS_FROM_UNITS = gql`
  query GetConsumptionFromUnitsReports($unitsIds: [ID]!) {
    last12MonthsRetroactiveByUnitIds(unitsIds: $unitsIds) {
      date
      energyTotalConsumption
    }
  }
`;
