import numeral from 'numeral';

import { formatCurrency, formatNumber } from '@utils/text';

import {
  GroupAccumulatedEconomyReportByGroupIdParsed,
  SummaryEconomyReportsHistoryParsed,
  UnitEconomyReportDataResponse,
  UnitReportParsed,
} from '@hooks/economy/types';

import { getDirectionFromValue } from '../functions';
import { EconomyReportIconCardData, UnitSummaryEconomyReport } from '../types';

export const buildEconomyCardDataFromSummary = (
  summary: SummaryEconomyReportsHistoryParsed,
): EconomyReportIconCardData => {
  const tooltip = {
    content: 'Em comparação com o mês anterior',
  };
  return {
    economy: {
      total: summary.monthlySavings.total,
      percentage: summary.monthlySavings.percentage,
      direction: summary.monthlySavings.typePercentageStyle,
      tooltip: tooltip,
    },
    energy: {
      cost: {
        total: summary.monthlyEnergyCost.total,
        direction: summary.monthlyEnergyCost.typePercentageStyle,
        percentage: summary.monthlyEnergyCost.percentage,
        tooltip: tooltip,
      },
      consumption: {
        total: summary.monthlyEnergyConsumption.total,
        direction: summary.monthlyEnergyConsumption.typePercentageStyle,
        percentage: summary.monthlyEnergyConsumption.percentage,
        tooltip: tooltip,
      },
    },
  };
};

export const buildEconomyCardDataFromUnitReport = (unitReport: UnitReportParsed): EconomyReportIconCardData => {
  return {
    economy: {
      total: unitReport.economy.total,
    },
    energy: {
      cost: {
        total: unitReport.energyCost.total,
      },
      consumption: {
        total: unitReport.energyConsumption.total,
      },
    },
  };
};

export const buildEconomyCardDataFromUnitEconomyReport = (
  unitReport: UnitEconomyReportDataResponse,
): EconomyReportIconCardData => {
  const tooltip = {
    content: 'Em comparação com o mês anterior',
  };
  const economyDirection = getDirectionFromValue(unitReport.savingBalancePercent);
  const costDirection = getDirectionFromValue(unitReport.energyCostBalancePercent);
  const consumptionDirection = getDirectionFromValue(unitReport.energyConsumptionBalancePercent);
  return {
    economy: {
      total: formatCurrency(unitReport.currentSavings),
      percentage: numeral(unitReport.savingBalancePercent / 100).format('%0.00'),
      direction: economyDirection,
      tooltip: tooltip,
    },
    energy: {
      cost: {
        total: formatCurrency(unitReport.energyTotalCost),
        direction: costDirection,
        percentage: numeral(unitReport.energyCostBalancePercent / 100).format('%0.00'),
        tooltip: tooltip,
      },
      consumption: {
        total: `${formatNumber(unitReport.energyTotalConsumption)} MWh`,
        direction: consumptionDirection,
        percentage: numeral(unitReport.energyConsumptionBalancePercent / 100).format('%0.00'),
        tooltip: tooltip,
      },
    },
  };
};

export const buildEconomyCardDataFromGroupAccumulatedReport = (
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed,
): EconomyReportIconCardData => {
  return {
    economy: {
      total: groupAccumulatedEconomyReport.totalSavings,
    },
    energy: {
      cost: {
        total: groupAccumulatedEconomyReport.totalEnergyCost,
      },
      consumption: {
        total: groupAccumulatedEconomyReport.totalEnergyConsumption,
      },
    },
  };
};

export const getUnitEconomyCardsData = (parsedUnits: UnitReportParsed[]): UnitSummaryEconomyReport[] => {
  return parsedUnits
    ? parsedUnits.map((units) => {
        return {
          unit: {
            id: units.unitId,
            name: units.unitName,
          },
          economy: units.economy.total,
          energy: {
            cost: units.energyCost.total,
            consumption: units.energyConsumption.total,
          },
        };
      })
    : [];
};
