import { Config } from 'tailwindcss';
import forms from '@tailwindcss/forms';
import { fotonTailwind as fotonTailwindV2 } from '@clarke-energia/foton-v2';

export const customColors = {
  progressPrimary: '#00DF7C',
  progressSecondary: '#80ACFF',
  progressWarning: '#FF6347',
};

const config: Config = {
  content: ['./index.html', './src/**/*.{js,jsx,ts,tsx}', './node_modules/@clarke-energia/foton-v2/**/*.{js,css}'],
  theme: {
    extend: {
      screens: {
        xs: '412px',
      },
      colors: {
        ...customColors,
      },
      backgroundImage: {
        greenGradient: 'linear-gradient(213deg, #00DF7C -2.02%, #BEF4C1 40%, #FFF 60.75%)',
      },
    },
  },
  plugins: [forms, fotonTailwindV2.fotonPlugin],
};

export default config;
