const LightIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 24.3334V17.3334M16 17.3334C16.6906 17.3334 17.3608 17.246 18 17.0814M16 17.3334C15.3094 17.3334 14.6392 17.246 14 17.0814M19 27.0526C18.0283 27.237 17.0254 27.3334 16 27.3334C14.9746 27.3334 13.9718 27.237 13 27.0526M18 30.2294C17.3427 30.2981 16.6755 30.3334 16 30.3334C15.3245 30.3334 14.6573 30.2981 14 30.2294M19 24.3334V24.0778C19 22.7673 19.8777 21.6467 21.0113 20.989C23.9941 19.2584 26 16.0301 26 12.3334C26 6.81053 21.5228 2.33337 16 2.33337C10.4772 2.33337 6 6.81053 6 12.3334C6 16.0301 8.00594 19.2584 10.9887 20.989C12.1223 21.6467 13 22.7673 13 24.0778V24.3334"
        stroke="#00BF6A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LightIcon;
