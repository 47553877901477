import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { CardNumber } from '@clarke-energia/foton-v2';

import configuration from '@config';
import { formatDateFromString } from '@utils/dayjs';

import DailyConsumptionChart from '@components/organisms/consumption/daily-consumption-chart';
import HourConsumptionChart from '@components/organisms/consumption/hour-consumption-chart';

import { UnitEconomyReport } from '@hooks/economy/get-units-reports/types';
import { ConsumptionsByUnit } from '@hooks/consumption/use-units-consumptions-by-group/types';
import { useGetConsolidatedMeasurementById } from '@hooks/consumption/use-get-consolidated-measurement-by-id';
import { useGetUnitControlMeasurementsByUnitIdAndMonthYear } from '@hooks/consumption/use-get-unit-control-measurements-by-unit-id-and-month-year';
import { useGetUnitDailyConsumptionProjectionByUnitIdAndMonthYear } from '@hooks/consumption/use-get-unit-daily-consumption-projection-by-unit-id-and-month-year';

import { parseMeasureDate } from '../helper';

import { getMonthlyConsumptionInformation } from './functions';
import style from './style.module.css';

interface MonthlyConsumptionByUnitProps {
  unitId: string;
  monthYear: string;
  consumptions?: ConsumptionsByUnit;
  economyReports: UnitEconomyReport[];
}

const MonthlyConsumptionByUnit = ({
  unitId,
  monthYear,
  economyReports,
  consumptions,
}: MonthlyConsumptionByUnitProps) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const currentMonthYear = (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '/' + currentYear;

  const isCurrentMonthSelected = monthYear == currentMonthYear;
  const [month, year] = monthYear.split('/');

  const currentMonthDate = dayjs()
    .set('month', parseInt(month) - 1)
    .set('year', parseInt(year))
    .set('date', 1);

  const lastMonthDate = currentMonthDate.subtract(1, 'month');

  const {
    loading: loadingMonthConsumptions,
    unitMonthConsumptions: unitDailyConsumptions,
    getUnitControlMeasurementsByUnitIdAndMonthYearHandler,
  } = useGetUnitControlMeasurementsByUnitIdAndMonthYear();

  const {
    loading: loadingDailyProjectedConsumption,
    getUnitDailyConsumptionProjectionByUnitIdAndMonthYearHandler,
    dailyProjection,
  } = useGetUnitDailyConsumptionProjectionByUnitIdAndMonthYear();

  const {
    loading: loadingHourlyConsumptions,
    measurements,
    getConsolidatedMeasurementByIdHandler,
  } = useGetConsolidatedMeasurementById();

  const [selectedDay, setSelectedDay] = useState('');

  useEffect(() => {
    getUnitControlMeasurementsByUnitIdAndMonthYearHandler(unitId, monthYear);
  }, []);

  useEffect(() => {
    if (unitDailyConsumptions.length > 0) {
      setSelectedDay(parseMeasureDate(unitDailyConsumptions[0]?.date ?? ''));
      getConsolidatedMeasurementByIdHandler(unitDailyConsumptions[0]?.consolidatedMeasurementDocId ?? '');
    }
  }, [unitDailyConsumptions]);

  const monthYearFormat = 'MM/YYYY';
  const reportDateFormat = 'YYYY-MM-DD 00:00:00';

  const unitReports =
    economyReports?.filter((report) => {
      return report.unit.id === unitId;
    }) ?? [];

  const monthUnitReport = unitReports.find((report) => {
    return report.date === currentMonthDate.format(reportDateFormat);
  });

  const lastMonthUnitReport = unitReports.find((report) => {
    return report.date === lastMonthDate.format(reportDateFormat);
  });

  const unitConsumption = consumptions ? consumptions[unitId] : null;

  const monthUnitConsumption = unitConsumption
    ? unitConsumption.consumptions.find((consumption) => {
        return consumption.referenceMonthYear === monthYear;
      })
    : {
        unitId: '-',
        referenceMonthYear: '',
        consumption: 0,
        averageConsumption: 0,
      };

  const lastMonthUnitConsumption = unitConsumption?.consumptions.find((consumption) => {
    return consumption.referenceMonthYear === lastMonthDate.format(monthYearFormat);
  });

  const unitName = useMemo(() => {
    return unitConsumption ? unitConsumption.name : '-';
  }, [unitConsumption]);

  const unitMonthlyConsumptionInfo = getMonthlyConsumptionInformation({
    consumption: {
      current: monthUnitConsumption,
      previous: lastMonthUnitConsumption,
    },
    report: {
      current: monthUnitReport,
      previous: lastMonthUnitReport,
    },
  });

  useEffect(() => {
    if (isCurrentMonthSelected) {
      getUnitDailyConsumptionProjectionByUnitIdAndMonthYearHandler(unitId, monthYear);
    }
  }, [isCurrentMonthSelected]);

  const hourlyConsumptionExcelPath = useMemo(() => {
    return `${configuration.CCEE_API_HOST}/api/v1/unit_consumption_excel/${unitId}/hourly/${formatDateFromString(
      selectedDay,
      'DD/MM/YYYY',
      'YYYY-MM-DD',
    )}`;
  }, [selectedDay]);

  return (
    <div className={style.container}>
      <div className={style.cardsContainer}>
        {unitMonthlyConsumptionInfo.map((item) => {
          return <CardNumber key={item.title} {...item} className={style.innerCard} />;
        })}
      </div>
      <div className={style.dailyConsumptionChart}>
        {loadingMonthConsumptions || loadingDailyProjectedConsumption ? (
          <Skeleton width={'100%'} height={'100%'} />
        ) : (
          <DailyConsumptionChart
            measures={unitDailyConsumptions ?? []}
            title={`Consumo por dia - ${unitName}`}
            projectedMeasures={dailyProjection?.projections ?? []}
            excelPath={`${configuration.CCEE_API_HOST}/api/v1/unit_consumption_excel/${unitId}/daily/${year}-${month}`}
            exportName={'grafico-de-consumo-diario-' + unitName.replace(' ', '-') + '-' + monthYear.replace('/', '-')}
          />
        )}
      </div>
      <div className="col-span-full">
        {loadingMonthConsumptions || loadingHourlyConsumptions ? (
          <Skeleton width={'100%'} height={'350px'} />
        ) : (
          <HourConsumptionChart
            title={`Consumo por hora - ${unitName}`}
            exportName={'grafico-de-consumo-por-hora-' + unitName.replace(' ', '-')}
            days={unitDailyConsumptions.map((el) => {
              return parseMeasureDate(el.date);
            })}
            defaultDay={selectedDay}
            consolidatedMeasures={measurements}
            onChangeDay={(day) => {
              const dayConsumption = unitDailyConsumptions.find((element) => {
                return parseMeasureDate(element.date) == day;
              });
              if (dayConsumption) {
                setSelectedDay(day);
                getConsolidatedMeasurementByIdHandler(dayConsumption?.consolidatedMeasurementDocId ?? '');
              }
            }}
            excelPath={hourlyConsumptionExcelPath}
          />
        )}
      </div>
    </div>
  );
};

export default MonthlyConsumptionByUnit;
