import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Skeleton from 'react-loading-skeleton';
import { CardNumber } from '@clarke-energia/foton-v2';

import { buildCardNumberProps } from './helper';
import { EconomyReportIconCardData } from '@pages/economy-reports/types';
import style from './style.module.css';

export interface EconomyCardsProps extends HTMLAttributes<HTMLDivElement> {
  economyReportIconCardData: EconomyReportIconCardData;
  loading: boolean;
  handleRedirect: (path: string) => void;
}

const AccumulatedEconomyCards: React.FC<EconomyCardsProps> = ({
  economyReportIconCardData,
  loading,
  handleRedirect,
  ...props
}) => {
  return (
    <div {...props} className={style.cardsContainer}>
      {buildCardNumberProps(economyReportIconCardData).map((item, index) => {
        return loading ? (
          <Skeleton height={200} key={`iconCard-${index}`} />
        ) : (
          <CardNumber
            buttonIcon={{
              icon: 'EyeIcon',
              kind: 'primary',
              size: 'small',
              onClick: () => {
                handleRedirect(item.redirectLink);
              },
              className: 'bg-primary-30',
            }}
            key={`iconCard-${index}`}
            title={item.title}
            value={item.value ?? '-'}
            variant={item.variant}
            icon={item.icon}
            ratio={item.ratio}
            tooltip={item.tooltip}
            className={twMerge(
              'shadow-md bg-[#FAFAFA]',
              index === 2 && 'bg-opacity-100 md:bg-opacity-80',
              index === 0 && 'bg-opacity-[82%] md:bg-opacity-100',
            )}
          />
        );
      })}
    </div>
  );
};

export default AccumulatedEconomyCards;
