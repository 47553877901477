import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { HOME_PATH } from '@routers/constants';

import Layout from '@components/templates/screen-layout';
import { SelectIcon } from '@components/atoms/form/select-icon';
import { ConsumptionSkeleton } from '@components/molecules/skeleton/consumption-skeleton';

import { useUserInfo } from '@hooks/use-user-info';
import { useConsumption } from '@hooks/use-consumption';
import { useUnitsSimpleEconomyReports } from '@hooks/economy/get-units-reports';
import { useUnitsConsumptionsByGroup } from '@hooks/consumption/use-units-consumptions-by-group';
import { useGetGroupAccumulatedEconomyReport } from '@hooks/economy/get-group-accumulated-economy-report';
import { useGroupMigrationStatus } from '@hooks/migration-report/use-group-migration-status';

import { formatDateFromString } from '@utils/dayjs';
import { GroupTypeEnum } from '@contexts/users/types';
import { formatMonthAndYearDate, sortObjectsByMonthYear } from '@utils/date';
import { ITotalMonthlyConsumption, SimpleConsumption } from '@contexts/consumption';

import AnnualConsumptionByUnit from './annual-consumption-by-unit';
import AnnualConsumptionByGroup from './annual-consumption-by-group';
import MonthlyConsumptionByUnit from './monthly-consumption-by-unit';
import MonthlyConsumptionByGroup from './monthly-consumption-by-group';

import { getUnitsConsumptionsMonth } from './helper';
import { parseGroupReportsToConsumptions, parseUnitReportsToConsumptionsByUnit } from './parses';

import style from './style.module.css';
import ExportButton from '@components/molecules/button/export-button';
import EmptyData from '@components/molecules/empty-data';

const ConsumptionHub: React.FC = () => {
  const exportRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const unit = searchParams.get('unitId');
  const date = searchParams.get('date');

  const [unitsOptions, setUnitOptions] = useState<Record<'label' | 'value', string | null>[]>();

  const { user, isFetchingUser } = useUserInfo();

  const { groupHasMigrated, getGroupHasMigrated, hasAtLeastOneMigratedUnit } = useGroupMigrationStatus();

  const isWholesaleClient = user?.group?.type === GroupTypeEnum.MANAGEMENT_WHOLESALE;

  const { totalMonthlyConsumptionByGroup, setGroupId, unitId, setUnitId, setMonthYear, monthYear, units, loading } =
    useConsumption();

  const {
    getGroupAccumulatedEconomyReportByGroupId,
    getGroupAccumulatedEconomyReportByUnitId,
    parsedData: { rawSummaryEconomyReportsHistory },
    loading: loadingGroupReports,
  } = useGetGroupAccumulatedEconomyReport();

  let monthlyConsumptionByGroup: SimpleConsumption[] = sortObjectsByMonthYear(
    totalMonthlyConsumptionByGroup || [],
    (consumption: ITotalMonthlyConsumption) => formatMonthAndYearDate(consumption.referenceMonthYear),
  );
  if (!isWholesaleClient) {
    monthlyConsumptionByGroup = parseGroupReportsToConsumptions(rawSummaryEconomyReportsHistory || []);
  }

  const { data: unitReports, loading: loadingUnitReports } = useUnitsSimpleEconomyReports();
  const { consumptions: consumptionsByUnit, loading: loadingUnitConsumptions } = useUnitsConsumptionsByGroup();

  let unitsConsumptions = consumptionsByUnit;
  if (!isWholesaleClient) {
    unitsConsumptions = parseUnitReportsToConsumptionsByUnit(unitReports || []);
  }

  useEffect(() => {
    if (user?.group) {
      setGroupId(user.group.id);
      getGroupHasMigrated(user.group.id);

      if (!isWholesaleClient) {
        getGroupAccumulatedEconomyReportByGroupId({ variables: { groupId: user.group.id } });
      }

      if (user.group.units.length === 1) {
        const unitId = user.group.units[0].id;
        setUnitId(unitId);

        getGroupAccumulatedEconomyReportByUnitId({
          variables: {
            unitId: unitId,
          },
        });
      } else {
        getGroupAccumulatedEconomyReportByGroupId({
          variables: {
            groupId: user.group.id,
          },
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (date) {
      const formattedMonthYear = formatDateFromString(date, 'YYYY-MM-DD', 'MM/YYYY');
      setMonthYear(formattedMonthYear);
    }

    if (unit) {
      setUnitId(unit);
    }
  }, [date, unit]);

  useEffect(() => {
    if (units && units.length === 1) {
      setUnitOptions([{ value: units[0].id, label: units[0].name }]);
      return;
    }

    setUnitOptions([
      { label: "Todas as UC's", value: null },
      ...(units ?? []).map(({ id, name }) => ({ value: id, label: name })),
    ]);
  }, [units]);

  if (isFetchingUser || loading || loadingUnitConsumptions || loadingUnitReports || loadingGroupReports) {
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <ConsumptionSkeleton />
      </Layout.Content>
    );
  }

  if (totalMonthlyConsumptionByGroup?.length === 0 || !hasAtLeastOneMigratedUnit) {
    return (
      <Layout.Content
        header={{
          title: 'Relatório de consumo',
          displayDesktop: true,
          additionalButtons: (
            <ExportButton className="mr-2" exportRef={exportRef} exportName={`relatorio-de-consumo`} />
          ),
          breadCrumbLinks: [
            {
              label: 'Home',
              url: HOME_PATH,
            },
            {
              label: 'Consumo',
              url: '',
            },
          ],
        }}
      >
        <EmptyData
          title={
            groupHasMigrated
              ? 'Você já começou a economizar no Mercado Livre de Energia.'
              : 'Você ainda não está no Mercado Livre de Energia.'
          }
          description={
            groupHasMigrated
              ? 'A migração para o Mercado Livre foi concluída com sucesso. Aqui, você encontrará as informações relacionadas ao seu consumo. Pedimos que aguarde a emissão do seu primeiro relatório, que estará disponível ao final do primeiro mês após a migração.'
              : 'A migração para o Mercado Livre ainda não foi concluída. Aqui você encontrará os relatórios referentes ao seu consumo assim que concluir o processo de migração.'
          }
        />
      </Layout.Content>
    );
  }

  const monthsByUnit = getUnitsConsumptionsMonth(consumptionsByUnit ?? {});

  let monthSelectOptions = !unitId
    ? monthlyConsumptionByGroup?.map(({ referenceMonthYear }) => ({
        value: referenceMonthYear,
        label: referenceMonthYear,
      })) || []
    : monthsByUnit[unitId]?.map((month) => {
        return {
          label: month,
          value: month,
        };
      }) || [];
  monthSelectOptions = sortObjectsByMonthYear(monthSelectOptions, (month) => month.label).reverse();

  if (monthYear) {
    if (!monthSelectOptions.find((option) => option.value === monthYear)) {
      let copySelects: typeof monthSelectOptions = JSON.parse(JSON.stringify(monthSelectOptions));
      copySelects.push({
        label: monthYear,
        value: monthYear,
      });
      copySelects = sortObjectsByMonthYear(copySelects, (month) => month.label).reverse();
      // for cases where a unit has more consumption months than another or than group
      let nextMonthOptionIndex = copySelects.findIndex((option) => option.value === monthYear);
      if (nextMonthOptionIndex === copySelects.length - 1) nextMonthOptionIndex--;
      let newMonthYear = undefined;
      if (copySelects.length > 1) {
        newMonthYear = monthSelectOptions[nextMonthOptionIndex].value;
      }
      setMonthYear(newMonthYear);
    }
  }

  return (
    <Layout.Content
      header={{
        title: 'Relatório de consumo',
        displayDesktop: true,
        additionalButtons: <ExportButton className="mr-2" exportRef={exportRef} exportName={`relatorio-de-consumo`} />,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Consumo',
            url: '',
          },
        ],
      }}
    >
      <div ref={exportRef}>
        {isWholesaleClient && (
          <div className={style.selectContainer}>
            <SelectIcon
              // @ts-expect-error null is required for selecting back all units
              options={unitsOptions}
              placeholder="Todas as UC's"
              onChange={(value) => {
                setUnitId(value);
              }}
              value={unitId}
              emptyValue={"Todas as UC's"}
            />
            <SelectIcon
              // @ts-expect-error null values is required for selecting back all months
              options={[{ label: 'Todos os meses', value: null }].concat(monthSelectOptions)}
              placeholder="Todos os meses"
              onChange={(value) => {
                setMonthYear(value);
              }}
              value={monthYear}
              emptyValue="Todos os meses"
            />
          </div>
        )}

        <div className={style.content}>
          {!unitId && !monthYear && consumptionsByUnit && (
            <AnnualConsumptionByGroup
              // TODO: sort reports by referenceMonth
              // TODO: get groups consumptions by reports (easy baby)
              // TODO(ronald): verify if consumption fields in unit register is used to economy reports
              unitConsumptions={unitsConsumptions ? Object.values(unitsConsumptions) : []}
              groupConsumptions={monthlyConsumptionByGroup || []}
            />
          )}
          {!unitId && monthYear && <MonthlyConsumptionByGroup />}
          {unitId && !monthYear && (
            <AnnualConsumptionByUnit
              unitId={unitId}
              consumptions={consumptionsByUnit || undefined}
              economyReports={unitReports || []}
            />
          )}
          {unitId && monthYear && (
            <MonthlyConsumptionByUnit
              unitId={unitId}
              monthYear={monthYear}
              consumptions={consumptionsByUnit || undefined}
              economyReports={unitReports || []}
            />
          )}
        </div>
      </div>
    </Layout.Content>
  );
};

export default ConsumptionHub;
