import { useMemo, useState } from 'react';
import { BarChart, LineChart, Switch } from '@clarke-energia/foton-v2';

import { Contract } from '@hooks/get-contracts/types';
import ChartContainer from '@components/molecules/charts/chart-container';

import { priceChartDataFormatted, volumeChartData } from './helpers';
import style from './style.module.css';

const ChartsSection = ({ contracts }: { contracts: Contract[] }) => {
  const [barUnit, setBarUnit] = useState<'MWH' | 'MWM'>('MWM');
  const barChart = volumeChartData(contracts, barUnit);
  const lineChart = useMemo(() => priceChartDataFormatted(contracts), [contracts]);

  return (
    <>
      <ChartContainer
        className={style.chartContainer}
        title={{ text: 'Volume contratado ao longo dos anos' }}
        tooltipContent="Volume contratado médio mensal"
      >
        <div className="flex flex-col mt-[18px]">
          <div className="flex justify-end text-paragraph-small">
            Mwh
            <span className="ml-3">
              <Switch
                onCheckedChange={(checked) => {
                  setBarUnit(checked ? 'MWM' : 'MWH');
                }}
                checked={barUnit === 'MWM'}
              />
            </span>
            Mw-m
          </div>

          <BarChart
            title=""
            stack
            series={barChart.series}
            labels={barChart.labels}
            lines={[]}
            lineSeries={[]}
            options={{
              legend: { show: true },
              rightSpace: 0,
              leftSpace: 40,
              yAxisLabel: {
                decimalPlaces: 3,
              },
            }}
          />
        </div>
      </ChartContainer>
      <ChartContainer
        className={style.chartContainer}
        title={{ text: 'Preço de energia (R$/MWh) contratado ao longo dos anos' }}
      >
        <LineChart
          title=""
          series={lineChart.series}
          labels={lineChart.labels}
          lines={[]}
          options={{
            legend: { show: true },
            rightSpace: 0,
            leftSpace: 30,
          }}
        />
      </ChartContainer>
    </>
  );
};

export default ChartsSection;
