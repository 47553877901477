import { useEffect, useState } from 'react';

/**
 * @description Hook that returns the size of an element
 * @param element - Ref or selector of the element to get the size
 */
export const useElementSize = (element: React.RefObject<HTMLElement> | string) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = typeof element === 'string' ? { current: document.querySelector(element) } : element;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) return;

      const entry = entries[0];
      if (entry) {
        setSize({ width: entry.contentRect.width, height: entry.contentRect.height });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return size;
};
